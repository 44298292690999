$background:#f5f4f9;
$color:#5c5776;
$default-color:#1a4080;
$border: #ededf5;
$muted:#a8a3b0;
$shadow:0 1px 3px rgba(92 ,89 ,143 , 0.3);

$primary-background:rgba(105, 100, 247, 0.15);
$primary-hover:#605af7;
$primary:#6964f7;
$secondary:#3571D5;
$info:#04b2e5;
$success:#21b632;
$warning:#fb9505;
$pink:#f5127b;
$danger:#f84242;
$blue:#096cf7;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#8b3ce0;
$orange:#e67605;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

$transparent-primary:rgba(105,100,247,0.1);
$transparent-secondary:rgba(239,80,80,0.1);
$transparent-warning:rgba(249, 167, 63, 0.15);
$transparent-info:rgba(0, 214, 230, 0.15);
$transparent-danger:rgba(255, 56, 43, 0.15);
$transparent-success:rgba(33, 182, 50, 0.15);

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-09:rgba(255, 255, 255, 0.09);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
