.item-card {
	.cardbody {
		position: relative;
		padding: 30px 15px;
		border-radius: 0 0 .2rem .2rem;
	}
	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}
		a {
			color: $color;
			font-weight: 500;
			text-decoration: none;
		}
	}
	.cardprice {
		position: absolute;
		top: 30px;
		right: 15px;
		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
			}
			display: block;
			color: $color;
		}
	}
}

@media (max-width: 400px) {
	.item-search-menu ul li a {
		width: 100%;
		display: block;
		margin: 0 0 8px 0 !important;
	}
}

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 600;
	}
}

.item-card {
	.item-card-desc:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(42, 16, 25, 0.5);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
		border-radius: 7px;
	}
	overflow: hidden;
	border-radius: 7px;
}

.card .item-card {
	border: 0;
}

.item-card-desc {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
}

.item-card .item-card-desc .item-card-text {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	text-align: center;
	color: $white;
	z-index: 2;
	&.item-card-text-footer{
		background: rgba(40, 40, 52, 0.45);
		bottom: 0;
		top: auto;
		text-align:left;
		padding: 15px 25px;
		h4{
			font-size:18px !important;
		}
		.fs-30{
			font-size:18px !important;
		}
		span{
			margin:0;
		}
	}
}

.item-card-text {
	span {
		font-size: 16px;
		margin: .5rem;
		font-weight: 400;
	}
	h4 {
		font-size: 22px;
		font-weight: 400;
	}
}

.item-card {
	.item-card-btn {
		padding: 0;
		margin: 0;
		opacity: 0;
		left: 37.7%;
		text-align: center;
		position: absolute;
		bottom: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover {
		.item-card-btn {
			opacity: 1;
			bottom: 25%;
		}
		.data1 .item-card-img img {
			transform: scale(1);
		}
	}
	.item-card-btn a {
		display: block;
		position: relative;
		transition: all .3s ease-in-out;
		&:after,
		&:before {
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			top: -30px;
		}
		&:after {
			content: '';
			height: 15px;
			width: 15px;
			border-radius: 0;
			transform: translateX(-50%) rotate(45deg);
			top: -20px;
			z-index: -1;
		}
	}
}

.item-card2-icons {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 98;
}

.item-card2 {
	.item-card2-text h4 {
		font-size: 20px;
	}
	ul li a {
		color: $color;
		i {
			color: $color;
		}
	}
}

.item-card2-icons a {
	width: 2rem;
	display: inline-block;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2.1rem;
	border-radius: 50px;
	color: $white;
	background: rgba(0, 0, 0, 0.25);
	&:hover {
		color: $white;
	}
}

.bg-black-trasparant {
	background: $black-5;
}

.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid $border;
	i {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		border-radius: 3px;
		font-size: 12px;
		text-align: center;
		line-height: 25px;
	}
}

.item-user .item-user-icons a {
	display: inline-block;
	width: 2.5rem;
	height: 2.5rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2.5rem;
	margin-top: .3rem;
	font-size:16px;
	background:$background;
	&:hover {
		color: $primary-hover;
	}
}

.item-card3-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
	position: relative;
	margin-bottom: -35px;
}

.item-card3-desc {
	img {
		border: 3px solid $white;
	}
	h4 {
		position: relative;
		top: 5px;
	}
}

.item-card4-img a {
	position: absolute;
	top: 35px;
	left: 30px;
}

.item-card5-img a {
	position: absolute;
	right: 12px;
	top: 15px;
}

.item-card5-icon a {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	border-radius: 50px;
	background: $border;
	color: $color;
}

.item-card6-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
}

.item-card6-footer .item-card6-body {
	padding: 10px;
}

.item-search-tabs {
	margin-top: 2rem;
	.item-search-menu ul {
		padding: 5px 15px;
	}
}

.item-search-menu ul li {
	.active {
		background: $black-2;
		color: $white;
		border-radius: 7px 7px 0 0;
	}
	a {
		padding: 8px 15px;
		color: $white;
		text-align: center;
		letter-spacing: .5px;
		background: 0 0;
		margin: 0 5px;
		border-radius: 3px 3px 0 0;
	}
}

.item-search-tabs {
	.tab-content {
		border: 1px solid $black-1;
		background: $black-2;
		padding: 15px;
		margin: 1px 5px 0 0;
		border-radius: 7px;
	}
	&.travel-content {
		.select2-container {
			width: 100% !important;
		}
		.form-control {
			border: 1px solid $border;
		}
	}
	.form-control {
		border: 0 !important;
	}
}

.tab-content.index-search-select .select2-container--default .select2-selection--single {
	border-radius: 0 !important;
}

.item-search-tabs {
	.form-control.border {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	a.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-size: .8525rem;
		right: -2px;
		position: relative;
		line-height: 1.94615385;
	}
}

@media (max-width: 1300px) and (min-width: 1024px) {
	.item-search-tabs a.btn {
		line-height: 1.94615385;
	}
}

.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 4px;
		margin-bottom: 1.5rem;
		color: $white;
	}
	.category-type .item-all-card img {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
		&:hover {
			box-shadow: 0 5px 12px rgba(126, 142, 177, 0.2);
		}
	}
}

.item-all-card {
	overflow: hidden;
	&:before {
		content: '';
		width: 100%;
		left: 0;
		height: 100%;
		bottom: -40px;
		position: absolute;
		display: block;
		opacity: 0.03;
		background: url(../images/png/bg.png);
		background-repeat: no-repeat;
		background-size: cover;
	}
	&.item-all-card2{
		&:before{
			display:none !important;
		}
	}
}

.item-all-cat .item-all-card a.absolute-link {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.item-all-card img.imag-service {
	width: 40%;
}

.item-card7-overlaytext {
	position: relative;
}

.item-card7-imgs .bdir {
	position: absolute;
	top: 12px;
	left: 12px;
	padding: 3px 7px;
	z-index: 2;
}

.item-card7-overlaytext {
	a {
		span {
			padding: 4px 10px;
			display: inline-block;
		}
		position: absolute;
		bottom: 12px;
		left: 12px;
		z-index: 2;
		background: $black-5;
		border-radius: 3px;
		padding: 5px;
	}
	h4 {
		position: absolute;
		bottom: 12px;
		right: 12px;
		color: $white;
		z-index: 2;
		padding: 5px;
		border-radius: 3px;
	}
}

.item-card7-imgs {
	position: relative;
	overflow: hidden;
	border-radius: 7px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.item-card8-img,
.item-card7-img,
.item-card9-img {
	height: 100%;
}

.item-card-badge {
    display: inline-block;
    padding: 4px 10px 4px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 7px;
    color: $default-color;
    background: $background;
	i {
		color: $color;
	}
}

.item-card8-imgs {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(25, 36, 79, 0.3);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.item-card8-overlaytext {
	position: relative;
	h6 {
		position: absolute;
		bottom: 21px;
		left: 21px;
		z-index: 2;
		padding: 8px;
		color: $white;
		border-radius: 3px;
	}
}

.item-card2-img:before {
	content: '';
	display: block;
	position: absolute;
	background: transparent;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 1;
}

.item-card7-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

@media (min-width: 569px) and (max-width: 767px) {
	.item-card7-imgs a i {
		top: 29%;
	}
}

.item-card9-img .item-card9-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

.item-card2-img a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

.item-card7-desc ul li {
	margin-right: 1rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
		margin-bottom: 0;
	}
}

.item1-card-img img {
	border: 1px solid $border;
	margin: 0 auto;
	padding: 5px;
}

.item1-card .item1-card-info .item1-card-icons li {
	display: inline-block;
	margin-right: 15px;
}

.item1-card-tab {
	text-align: center;
	.tab-content .tab-pane {
		border: 1px solid $border;
	}
}

.item1-tab-heading {
	padding: 15px;
	border: 1px solid $border;
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
}

.item1-tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: $white;
		border-radius: 25px;
	}
}

.item1-card-tab .tab-content {
	padding: 15px;
	text-align: initial;
}

.item-video {
	a {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		background: $white;
		color: $color;
		font-size: 25px;
		align-items: center;
		margin: 0 auto;
		justify-content: center;
	}
	i {
		margin-left: 5px;
	}
}

.item2-card .item2-card-img {
	border: 1px solid $border;
	padding: 5px;
	img {
		width: 200px;
		height: 100px;
		text-align: center;
		margin: 0 auto;
	}
}

.item2-card-info {
	padding-top: 15px;
}

.item2-btn {
	padding-top: 40px;
}

.items-gallery .items-blog-tab-heading {
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
	padding: 15px;
	margin-bottom: 30px;
}

.items-blog-tab-heading .items-blog-tab-menu li a {
	padding: 10px 20px 11px 20px;
	color:$color;
	background: $background;
	margin: 0 10px;
	border-radius: 7px;
}

.items-gallery .tab-content {
	padding: 15px;
	text-align: initial;
}

.item2-gl .item2-gl-nav select {
	width: 20%;
}

.item2-gl-nav {
	padding: 15px;
	border: 1px solid $border;
	border-radius: 7px;
	background: #fbf8fd;
	.item2-gl-menu {
		margin-right: 10px;
		margin-top: 54px;
		border: 1px solid $border;
		border-radius: 7px;
		li a {
			color:$color;
			i {
				padding: 8px 15px;
				font-size: 21px;
				border-radius: 7px;
			}
		}
	}
}

.item2-gl .tab-content {
	padding-top: 1.5rem;
	text-align: initial;
}

.item-card9-img {
	position: relative;
	height: 100%;
}

.item-card9-icons {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 98;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		border-radius: 50px;
		color: $white;
		background: $black-3;
		&:hover {
			color: $white;
		}
	}
}

.item-card9-cost h3 {
	margin-top: 5px;
}

.item-card9-imgs {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-card9-desc ul {
	display: inline-flex;
	margin-bottom: 0;
	li {
		width: 50%;
		float: left;
	}
}
.item-card9-desc {
	.icon-card  {
		margin: 0px !important;
		display: inline-block  !important;
		li {
			float: left;
			font-size: 13px;
			width: 50%;
			margin-bottom: .5rem;
		}
	}
}

.item3-medias .item3-lists li {
	a {
		padding: 5px 4px 5px 0;
		font-size: 12px;
	}
	i {
		margin-right: 5px;
	}
	margin-right: 5px;
}

.item-card2-text h4 {
	font-weight: 600;
}

.item-card2-desc p {
	color: $color;
	font-size: 16px;
	line-height: 21px;
}

.item-all-cat {
	.item-all-card {
		border: 1px solid $border;
		box-shadow: 0 10px 23px rgba(214, 215, 242, 0.6);
		a:hover .item-all-text h5 {
			color: $color !important;
		}
	}
	.category-type .item-all-card:hover {
		background: $background;
	}
}

.index-search-select .select2 {
	width: 100% !important;
}

@media (max-width: 767px) {
	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-bottom: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
			width: 30%;
		}
		.d-flex {
			text-align: center;
			width: 100%;
		}
		.form-control.select-sm.w-70 {
			width: 70% !important;
		}
	}
}

@media (max-width: 320px) {
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 11px;
	}
	.item-card .item-card-desc .item-card-text {
		top: 5.5rem !important;
	}
	.item7-card-desc a {
		font-size: 11px;
	}
}

.item-single .item-single-desc .item-single-desc-list .listunorder {
	border: 0;
	padding: 5px;
	margin-bottom: 0;
	font-size: 15px;
}

.item-card2-img {
	position: relative;
	overflow: hidden;
	border-radius: 3px 3px 0 0;
}

.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;
	float: left;
	width: 50%;
	margin-right: 0;
	margin-bottom: .5rem;
	&:last-child {
		margin-right: 0;
		margin-bottom: 0;
	}
	&:nth-last-child(2) {
		margin-bottom: 0;
	}
}

.item7-card-desc a:first-child {
	margin-right: 1rem;
}

.item7-card-img {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 7px;
	border-top-left-radius: 7px;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-cards7-ic {
	display: inline-block;
	&.realestate-list li {
		a {
			i {
				width: 1rem;
			}
			color: $color;
		}
		width: 50%;
	}
	li {
		float: left;
		width: 50%;
		margin-right: 0 !important;
		margin-bottom: .5rem;
	}
}

.item7-card-text span {
	position: absolute;
	bottom: 10px;
	left: 10px;
	&.badge {
		line-height: 1.5;
	}
}

.item-card-img {
	overflow: hidden;
}

.item-card .item-card-desc {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
		width: 100%;
		height: 100%;
	}
	&:hover img {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}

.item-card7-imgs {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.item-card9-imgs {
	overflow: hidden;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
}
.item-card2 p.leading-tight {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	margin: 0 auto;
	font-size: 16px;
	line-height: 1;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item-card9-imgs:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.item-card9 {
	h3 {
		font-size: 1.3rem !important;
	}
	p {
		font-size: 16px;
		line-height: 21px;
	}
}

.item-card2-img {
	img {
		-webkit-transform: scale(1.15);
		transform: scale(1.15);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}

.item1-links a {
	padding: .75rem 1.25rem;
	font-weight: 400;
	font-size: .875rem;
	color: $color;
	&.active {
		font-weight: 400;
		font-size: .875rem;
	}
}

.item-user span i {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: $background;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	padding: 12px;
}

@media (max-width: 767px) {
	.item-user .profile-pic .d-md-flex .ml-4 {
		margin-left: 0 !important;
		margin-top: 15px;
	}
}

.item-card7-img {
	position: relative;
	border-radius: 3px 3px 0px 0;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.item-card7-desc p {
	font-size: 16px;
	line-height: 21px;
}

.item-card7-text {
	margin-bottom: 12px !important;
	h4 {
		font-weight: 600;
		font-size: 18px;
	}
}

.item-card7-img {
	a:hover {
		color: $white;
	}
	h4 {
		margin: 2px;
		color: $white;
		padding: 5px;
		border-radius: 3px;
		float: right;
	}
}

@media (max-width: 767px) {
	.item2-gl-nav {
		.item2-gl-menu {
			display: none;
		}
		h6 {
			margin-bottom: 20px !important;
		}
	}
}

.iteam-all-icon,
 ::-ms-backdrop {
	height: 100%;
}

.item-overly-trans {
	.rating-stars {
		position: absolute;
		bottom: 12px;
		left: 12px;
		z-index: 2;
		background: $black-5;
		border-radius: 7px;
		padding: 5px;
	}
	a {
		position: absolute;
		bottom: 15px;
		left: 15px;
		z-index: 2;
		background:$background;
		color: $white;
		border-radius: 3px;
		padding: 1px 10px;
	}
	.rating-stars .rating-stars-container .rating-star {
		&.sm {
			color: $white-5;
		}
		&.is--active {
			color: $color;
		}
	}
}

.rating-stars .rating-stars-container .rating-star.is--hover {
	color: $color;
}

.item-tag {
	h4 {
		margin: 2px;
		color: $white;
		background: $danger;
		padding: 5px;
		border-radius: 3px;
		float: right;
	}
	position: absolute;
	bottom: 15px;
	right: 15px;
	z-index: 0;
}

.item-card7-icons {
	position: absolute;
	bottom: 12px;
	left: 15px;
	z-index: 0;
	a {
		&:first-child{
			border-radius:8px 0 0 8px;
		}
		&:last-child{
			border-radius:0 8px 8px 0;
		}
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		color: $white;
		background: $background;
		margin-right:-2px;
		border: 1px solid $border;
		&:hover {
			color: $white;
		}
	}
}

.item-overly-trans .rating-stars {
	position: absolute;
	bottom: 12px;
	left: 15px;
	z-index: 2;
	background: $black-5;
	border-radius: 3px;
	padding: 5px;
}

.item-tags {
	position: absolute;
	bottom: 12px;
	left: 10px;
	z-index: 98;
	.tag-option {
		margin: 5px;
		padding: 3px 5px;
		font-size: 12px;
		border-radius: 3px;
		color: $white;
		float: left;
		cursor: pointer;
	}
}

.item:hover .card {
	box-shadow: 0 5px 12px rgba(126, 142, 177, 0.0);
}

.item-card7-imgs:hover a,
.item-card2-img:hover a {
	background: transparent;
}

.item-card7-overlaytext h4 {
	background: $danger;
}

.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 7px;
		margin-bottom: 10px;
		color: $white;
	}
	.category-type .item-all-card img {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
	}
	.item-all-card a.absolute-link {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-all-card img.imag-service {
	width: 40%;
}

.item-card7-img h4 {
	background: $danger;
}

.item-card2-footer a.btn {
	margin: 3px 0;
}

.item-card9-icons2 {
    position: absolute;
    bottom: 11px;
    right: 15px;
    z-index: 98;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		border-radius: 50px;
		color: $default-color;
		background: rgb(239, 240, 255);
	}
}
.item-overly-trans2 a {
    position: absolute;
    top: 24px;
    right: 15px;
    z-index: 2;
    border-radius: 7px;
}
.item-card-text-bottom {
    position: absolute;
    bottom: 0px;
    padding: 15px 30px;
    right: 0;
    font-size: 18px;
    border-radius: 7px 0 7px 0;
    z-index: 1;
    margin-right: 24px;
    transition: all 0.3s;
	h4 {
		font-size: 16px;
		font-weight: 500;
		color:$white
	}
	h5 {
		font-size: 13px;
		margin-top: 10px;
	}
}

.item-card {
	.item-card-btn {
		&.data-1 {
			transform: translate(-50%,-50%);
			top: 50%;
			left: 50%;
			bottom: auto;
		}
	}
}