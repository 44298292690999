*::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    transition: .3s background;
}

::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
    background: #e1e6f1;
}

.z-index-0 {
	z-index:0;
}

.social-box {
	min-height: 160px;
	margin-bottom: 20px;
	text-align: center;
	background: $white;
	border-radius: 8px;
	box-shadow: 0 0 0 1px rgba(181, 201, 227, 0.12), 0 8px 16px 0 rgba(181, 201, 227, 0.24);
	i {
		display: block;
		font-size: 40px;
		background: #e9ecef;
		line-height: 110px;
	}
	&.facebook i {
		color: $white;
		background: #5e5baa;
		border-radius: 8px 8px 0 0;
	}
	ul {
		list-style: none;
		display: inline-block;
		margin: 7px 0 0;
		padding: 10px;
		width: 100%;
		li {
			color: #949ca0;
			font-size: 14px;
			font-weight: 700;
			padding: 0 10px 0 0;
			text-align: center;
			display: block;
			float: left;
			width: 50%;
			line-height: 2;
			strong {
				display: block;
				font-size: 17px;
				line-height: 1;
				color: $color;
			}
			span {
				font-size: 15px;
				font-weight: 500;
				color: $color;
				text-transform: uppercase;
			}
			&:last-child {
				padding-left: 10px;
				padding-right: 0;
				text-align: center;
			}
		}
	}
	&.twitter i {
		color: $white;
		background: #00bcd4;
		border-radius: 8px 8px 0 0;
	}
	&.linkedin i {
		color: $white;
		border-radius: 8px 8px 0 0;
	}
	&.google-plus i {
		color: $white;
		background: #ff3b2e;
		border-radius: 8px 8px 0 0;
	}
}

.statistics-box {
	&.with-icon {
		position: relative;
		padding-left: 80px;
		text-align: right;
		.ico {
			&.small {
				font-size: 50px;
			}
			position: absolute;
			top: 8px;
			left: 0;
			width: 80px;
			height: 80px;
			font-size: 70px;
			line-height: 80px;
			text-align: center;
		}
	}
	padding-top: 14px;
	min-height: 80px;
	text-align: center;
}

.content-box h4 {
	font-size: 43px !important;
}

.counter {
	font-size: 35px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.counter-1 {
	font-size: 40px;
	font-weight: 600;
}

.todo-item {
	+.todo-item {
		margin-top: 8px;
	}
	.checkbox {
		margin-bottom: 6px;
	}
	input:checked+span {
		text-decoration: line-through;
	}
}

.iconfont {
	h2 {
		font-size: 45px;
		font-weight: 600;
		margin-bottom: 10px !important;
	}
	h5 {
		font-size: 16px;
		font-weight: 600;
	}
	i {
		font-size: 15px;
	}
}

.wx h1 {
	font-size: 65px !important;
	margin-bottom: 15px;
	margin-top: 0;
}

.other {
	.msg {
		order: 1;
		border: 1px solid $border;
		border-top-left-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	&:before {
		content: "";
		position: relative;
		top: 0;
		right: 0;
		left: 50px;
		width: 0;
		height: 0;
		border: 5px solid $border;
		border-left-color: transparent;
		border-bottom-color: transparent;
	}
}

.self {
	justify-content: flex-end;
	align-items: flex-end;
	.msg {
		order: 1;
		border: 1px solid $border;
		border-bottom-right-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	.avatar {
		order: 2;
		&:after {
			content: "";
			position: relative;
			display: inline-block;
			bottom: -14px;
			right: 26px;
			width: 0;
			height: 0;
			border: 5px solid $border;
			border-right-color: transparent;
			border-top-color: transparent;
			box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		}
	}
}

.msg {
	background: $white;
	min-width: 50px;
	margin: 0 10px;
	padding: 10px;
	border-radius: 3px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
	p {
		font-size: 13px;
		margin: 0 0 .2rem 0;
		color: #313148;
	}
	img {
		position: relative;
		display: block;
		width: 450px;
		border-radius: 3px;
		box-shadow: 0 0 3px #eee;
		transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	time {
		font-size: .7rem;
		margin-top: 3px;
		float: right;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		&:before {
			content: "\f017";
			font-family: FontAwesome;
			display: inline-block;
			margin-right: 4px;
		}
	}
}

.social-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	text-shadow: none;
	color: $white !important;
}

.socials {
	display: flex;
	li {
		margin: 0 12px;
		margin-right: 15px;
	}
}

.text-shadow1 {
	text-shadow: 0 10px 10px rgba(39, 29, 96, 0.4);
}

.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.description-block {
	display: block;
	margin: 10px 0;
	text-align: center;
	> {
		.description-header {
			margin: 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}
		.description-text {
			text-transform: uppercase;
			color: $color;
		}
	}
}

.socailicons {
	.fa {
		font-size: 70px;
		text-align: right;
		position: absolute;
		top: 13px;
		right: -5px;
		outline: 0;
	}
	color: $white;
	text-shadow: 1px 1px 2px $black-5;
	a {
		text-decoration: none;
	}
}

.background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	z-index: 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: .7;
	z-index: 1;
}

.masthead {
	position: relative;
	overflow: hidden;
	padding-bottom: 3rem;
	z-index: 2;
	.masthead-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		min-height: 35rem;
		height: 100%;
	}
	.masthead-content {
		h1 {
			font-size: 2rem;
		}
		p {
			font-size: 1rem;
			strong {
				font-weight: 700;
			}
		}
		.input-group-newsletter {
			input {
				height: auto;
				font-size: 1rem;
				padding: 1rem;
			}
			button {
				font-size: .8rem;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1px;
				padding: 1rem;
			}
		}
	}
}

@media (min-width: 1025px) {
	.masthead .masthead-content {
		padding-left: 7rem;
		padding-right: 7rem;
	}
}
@media screen and (max-width: 800px) {
	.msg img {
		width: 300px;
	}
}

@media screen and (max-width: 550px) {
	.msg img {
		width: 200px;
	}
}

@media (min-width: 992px) {
	.masthead {
		height: 100%;
		min-height: 0;
		width: 40%;
		padding-bottom: 0;
		float: right;
		.masthead-bg {
			min-height: 0;
		}
		.masthead-content {
			h1 {
				font-size: 2.5rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			padding: 0.35rem;
			box-shadow: 2px 3px 4.7px 0.3px rgba(92, 89, 143, 0.2);
			.ad-post {
				margin-top: 0.63rem !important;
			}
		}
		.horizontalMenu>.horizontalMenu-list>li>a {
			>.fa {
				color: $color;
			}
			&.active .fa {
				color: $white !important;
			}
		}
		.desktoplogo-1 {
			display: block !important;
			padding: 0.65rem 0;
			margin: 0;
			float: left;
		}
		.header-style1 .top-postbtn {
			margin-top: 0 !important;
		}
		.horizontalMenu>.horizontalMenu-list>li {
			padding: 0.70rem 0;
			margin-top:0 !important;
		}
	}
	.desktoplogo-1,
	.sticky-wrapper.is-sticky .desktoplogo {
		display: none;
	}
}

.ico-listing.table {
	td,
	th {
		padding: .75rem;
		vertical-align: middle;
	}
}

.sticky-wrapper {
	z-index: 1000;
	position: absolute;
	left: 0;
	right: 0;
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		>.table-bordered {
			border: 0;
		}
	}
	.text-wrap .table-responsive-sm>table {
		border: 0;
	}
	.owl-carousel.slider .owl-nav button {
		&.owl-prev,
		&.owl-next {
			display: none !important;
		}
	}
	.about-widget h2 {
		font-size: 30px !important;
	}
	.video-list-thumbs .class-icon {
		top: 55px !important;
	}
}

.notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 36px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}

.app-selector ul {
	margin-bottom: 0;
}

.block {
	display: block !important;
}

.user-semi-title {
	font-size: 12px;
}

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}

#global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.loader-img {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 45%;
	margin: 0 auto;
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: $white;
	border-radius: 3px;
}

#copy {
	background: 0 0;
	color: $white;
	font-weight: 700;
	padding: 8px 25px;
	border: 0;
}

.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-primary {
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: $info;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $yellow;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: $danger;
	color: $white;
	padding: 5px;
}

@media (max-width: 480px) {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			display: inline-block;
			padding-right: .2rem;
			padding-left: .2rem;
			color: $color;
			content: "/";
		}
		padding-left: 0;
	}
	.page-header {
		display: block !important;
		padding: 10px 0 !important;
		.form-control.page-select {
			width: 100% !important;
			margin-bottom: 1rem;
		}
	}
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

.profie-img p {
	font-size: 15px;
	line-height: 21px;
}

.close {
	float: right;
	font-size: 1.40625rem;
	font-weight: 700;
	line-height: 1;
	color: $color;
	opacity: .7;
	&:focus,
	&:hover {
		color: #a0afc7;
		text-decoration: none;
		opacity: .75;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
}

@media (max-width: 576px) {
	.header-text h1 {
		font-size: 2rem;
	}
	.classified-video h1 {
		font-size: 1.2rem;
	}
	.section-title h2 {
		font-size: 1.5rem;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item,
	embed,
	iframe,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.embed-responsive-21by9::before {
	padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
	padding-top: 56.25%;
}

.embed-responsive-4by3::before {
	padding-top: 75%;
}

.embed-responsive-1by1::before {
	padding-top: 100%;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-italic {
	font-style: italic !important;
}

@media print {
	*,
	 ::after,
	 ::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	blockquote,
	pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	img,
	tr {
		page-break-inside: avoid;
	}
	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	body,
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid $black;
	}
	.table,
	.text-wrap table {
		border-collapse: collapse !important;
	}
	.table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.text-wrap table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.table-bordered {
		td,
		th {
			border: 1px solid $border !important;
		}
	}
	.text-wrap table {
		td,
		th {
			border: 1px solid $border !important;
		}
	}
}

.lead {
	line-height: 1.4;
}

blockquote {
	color: $color;
	padding-left: 2rem;
	border-left: 3px solid rgba(227, 237, 252, 0.5);
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right: 3px solid rgba(227, 237, 252, 0.5);
	border-left: 0;
}

blockquote {
	&.float-right {
		padding-right: 15px;
		padding-left: 0;
		text-align: right;
		border-right: 3px solid rgba(227, 237, 252, 0.5);
		border-left: 0;
	}
	p {
		margin-bottom: 1rem;
	}
	cite {
		display: block;
		text-align: right;
		color: $color;
		&:before {
			content: '— ';
		}
	}
}

code {
	background: $border;
	border: 1px solid $border;
	border-radius: 3px;
	padding: 3px;
}

pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: 0 0;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

pre {
	color: $dark;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #d8dee6;
	border-radius: 3px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

img {
	max-width: 100%;
}

.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid $border;
	border-radius: 3px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}

@media print {
	.container {
		max-width: none;
	}
}
.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}

body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0 !important;
	}
	.construction-image .page-content {
		z-index: 9;
	}
}

@media (max-width: 768px) {
	.item-det {
		ul.d-flex,
		.d-flex {
			display: block !important;
		}
		.rating-stars.d-flex {
			display: flex !important;
		}
	}
	.mrt-sm-2 {
		margin-top: 0.5rem !important;
	}
}

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: $white;
}

.users-list .page-header {
	background: 0 0;
	padding: 0;
	border: 0;
	padding: 1rem !important;
}

.bannerimg .breadcrumb {
	display: inline-flex;
}

.page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 2.5rem;
}

.page-title-icon {
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: .8125rem;
	color: #5f6877;
	position: relative;
	top: 19px;
	right: 59rem;
	a {
		color: inherit;
	}
}

.page-options {
	margin-left: auto;
}

.page-breadcrumb {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.page-description {
	margin: .25rem 0 0;
	color: #5f6877;
	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
	&:first-child {
		margin-top: 0;
	}
}

.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid $border;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px $black-1;
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid $border;
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

.icon i {
	vertical-align: -1px;
}

a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.shadow {
	box-shadow: 0 1px 2px 0 $black-05 !important;
}

.shadow-none {
	box-shadow: none !important;
}

.user-img {
	margin-top: 2px;
}

@media (max-width: 480px) {
	.list-media .info {
		.text-end {
			display: none;
		}
		padding-bottom: 15px;
	}
	.tabs-menu ul li {
		width: 100%;
		margin: 5px 15px;
		a {
			width: 100%;
		}
	}
	.cal1 .clndr .clndr-table tr {
		height: 50px !important;
	}
	.tabs-menu1 ul li {
		width: 100% !important;
		margin: 2px;
		a {
			width: 100%;
		}
	}
	.wizard-card .nav-pills>li {
		text-align: center;
		padding: 9px !important;
	}
	.form-control.form-select.w-auto {
		display: none;
	}
	.mail-inbox .badge {
		margin: 0;
	}
	.construction .display-5 {
		font-size: 1.5rem;
	}
	.jumbotron .display-3 {
		font-size: 2.5rem;
	}
	.mail-option .hidden-phone {
		display: none;
	}
}

@media (max-width: 480px) {
	.app-header .header-brand {
		min-width: 2.8rem;
	}
	.app-header1 .header-brand {
		min-width: auto;
	}
	.menu-toggle-button {
		margin-top: 4px;
	}
}

@media (max-width: 767px) {
	.avatar {
		max-width: inherit;
	}
	.card-tabs {
		display: block !important;
	}
	.header-brand {
		line-height: 2.7rem;
	}
	.header .input-icon.mt-2 {
		margin-top: 5px !important;
	}
	.footer .privacy {
		text-align: center !important;
	}
	.shop-dec .col-md-6 {
		&.pe-0 {
			padding-right: 0.75rem !important;
		}
		&.ps-0 {
			padding-left: 0.75rem !important;
		}
	}
}

@media (max-width: 375px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
	.construction h3 {
		font-size: 2.8rem !important;
	}
}

@media (max-width: 411px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
}

@media (max-width: 414px) {
	.nav-link span {
		margin-top: .6rem;
	}
	.searching1 i {
		margin-top: .5rem;
		top: 14px !important;
	}
}

@media (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
	}
	.page-subtitle {
		display: none;
	}
	.richText .richText-toolbar ul li a {
		border: rgba(0, 40, 100, 0.12) solid 1px;
	}
	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}
		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}
	.dataTables_wrapper {
		.dataTables_info,
		.dataTables_paginate {
			margin-top: 10px;
		}
	}
	.page-title {
		line-height: 1.5rem;
	}
	.carousel-caption {
		display: none;
	}
	.demo-gallery>ul>li {
		width: 100% !important;
	}
	ul.inbox-pagination li span {
		display: none;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}
	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}
	.messages-left .card-header {
		display: none;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
}

@media (max-width: 990px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.messages-left .card-header {
		display: none;
	}
	.messages-list {
		top: 24px !important;
	}
}

@media (max-width: 990px) {
	.header-toggler {
		margin: 6px;
	}
	.nav-tabs .nav-link {
		width: 100%;
	}
	form.convFormDynamic button.submit {
		margin: 4px !important;
	}
	.messages-list .media {
		padding: 9px !important;
	}
	.nav-tabs {
		z-index: 1000;
	}
}

.page-breadcrumb {
	background: 0 0;
	padding: 0;
	margin: 1rem 0 0;
	font-size: .875rem;
	.breadcrumb-item {
		&.active {
			color: #5f6877;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float: right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}
	.construction h3 {
		font-size: 2.3rem !important;
	}
}

.page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #5f6877;
}

.tilebox-one i {
	font-size: 62px;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}

.profie-img .flex-md-row img {
	width: 60px;
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: .5rem;
}

.legend li {
	padding: 5px;
	display: -webkit-inline-box;
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
	>.card {
		width: 100%;
		min-width: 100%;
	}
}

.icon-size {
	font-size: 2.5rem !important;
}

.statistics-box {
	position: relative;
	padding-left: 80px;
	text-align: right;
	padding-top: 14px;
	min-height: 80px;
	text-align: center;
	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}
	h2 {
		font-weight: 600 !important;
	}
}

.visitor-list {
	p {
		font-size: 12px;
	}
	i {
		font-size: 20px;
	}
}

.sales-relative {
	position: relative;
}

.icon-absolute {
	position: absolute;
	display: block;
	bottom: 10px;
	right: 10px;
	padding: 20px 0;
	text-align: center;
	border-radius: 50%;
	width: 70px;
	height: 70px;
}

@media (min-width: 991px) and (max-width: 1024px) {
	.icon-absolute {
		display: none;
	}
}

.country-selector .avatar {
	width: 1.4rem;
	height: 1rem;
	line-height: 1rem;
}

.product-price {
	font-size: 1rem;
	strong {
		font-size: 1.5rem;
	}
}

@-webkit-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.link-overlay {
	position: relative;
	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $black-5;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}

textarea[cols] {
	height: auto;
}

.fs {
	font-size: 25px;
}

.searching1 i {
	position: absolute;
	top: 21px;
	margin-left: 15px;
	font-size: 16px;
	color: #fcfdff;
}

.search-inline {
	width: 100%;
	left: 0;
	padding-top: 1.5rem;
	padding-bottom: .75rem;
	top: 0;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	background-color: $white;
	box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	z-index: 9;
	transition: all .1s ease-in-out;
	&.search-visible {
		opacity: 1;
		visibility: visible;
		padding-top: 3rem;
		right: 0;
		padding-bottom: .5rem;
		border: 1px solid $border;
		animation: fadeInDown .1s ease-in-out;
	}
	button[type=submit] {
		position: absolute;
		right: 52px;
		top: 0;
		background-color: transparent;
		border: 0;
		top: 0;
		right: 80px;
		padding: 0;
		cursor: pointer;
		width: 80px;
		height: 99%;
		background: $white;
	}
	.form-control {
		border: 0;
		font-size: 15px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 99%;
		font-weight: 700;
		outline: 0;
	}
	.search-close {
		position: absolute;
		top: 21px;
		right: 0;
		width: 80px;
		height: 100%;
		text-align: center;
		display: table;
		background: $white;
		text-decoration: none;
		i {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.sparkline {
	display: inline-block;
	height: 2rem;
}

.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: #333 !important;
	border: none !important;
	border-radius: 3px;
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	padding: 6px !important;
	.jqsfield {
		font: inherit !important;
	}
}

.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

.status-icon {
	content: '';
	width: .5rem;
	height: .5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-right: .375rem;
	vertical-align: middle;
}

.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}

.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}

.chips {
	margin: 0 0 -.5rem;
}

.team i {
	margin-left: 10px;
	float: right;
	margin-top: 10px;
	color: #313148;
}

.chips .chip {
	margin: 0 .5rem .5rem 0;
}

.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: .875rem;
	font-weight: 500;
	color: $color;
	margin: 2px;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: $background;
	transition: .3s background;
	.avatar {
		float: left;
		margin: 0 .5rem 0 -.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}

a.chip:hover {
	color: $white;
	text-decoration: none;
}

.emp-tab table {
	margin-bottom: 0;
	border: 0;
}

.stamp {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 3px;
	font-weight: 600;
}

.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}

.stamp-lg {
    min-width: 4rem;
    height: 4rem;
    line-height: 4.5rem;
    border-radius: 100%;
	svg{
		width: 2.5rem;
		height: 2.5rem;
		&.stamp-svg2 {
			fill:$success;
		}
		&.stamp-svg3 {
			fill:$info;
		}
		&.stamp-svg4 {
			fill:$danger;
		}
	}
}

.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 3px;
	border: 1px solid $border;
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
	&:before {
		content: '';
		opacity: 0;
		position: absolute;
		top: .25rem;
		left: .25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: .3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
}

.colorinput-input:checked~.colorinput-color:before {
	opacity: 1;
}

svg {
	-ms-touch-action: none;
	touch-action: none;
}

#back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	-o-border-radius: 7px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		padding-top: 15px;
		font-size: 16px;
	}
}

.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
}

.col-sm-2 .feature {
	padding: 0;
	border: 0;
	box-shadow: none;
}

.feature {
	.fea-icon {
		position: relative;
		display: inline-block;
		width: 3rem;
		height: 3rem;
		vertical-align: middle;
		padding-top: 8px;
		border-radius: 50%;
		color: $white;
		i {
			font-size: 1.5rem;
		}
	}
	p {
		margin-bottom: 0;
	}
}

.box-shadow-0 {
	box-shadow: none !important;
}

#messages-main {
	position: relative;
	margin: 0 auto;
	&:after,
	&:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid $border;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
		&.toggled {
			display: block;
		}
	}
	.ms-body {
		overflow: hidden;
	}
	.ms-user>div {
		overflow: hidden;
		padding: 3px 5px 0 15px;
		font-size: 11px;
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: '\f2ea';
	}
}

.login-content:after {
	content: "";
}

.message-feed {
	padding: 10px;
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail {
	text-align: center;
}

.four-zero {
	text-align: center;
	footer>a {
		text-align: center;
	}
}

.ie-warning,
.login-content,
.login-navigation {
	text-align: center;
}

.pt-inner {
	text-align: center;
	.pti-footer>a {
		text-align: center;
	}
}

.message-feed.right {
	>.pull-end {
		margin-left: 15px;
	}
	.mf-content {
		background: $border;
	}
}

.mf-content {
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: $border;
		right: -20px;
		top: 11px;
	}
}

.mf-date {
	display: block;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}

.msb-reply {
	position: relative;
	margin-top: 3px;
	background: $white;
	padding: 10px;
	border-radius: 0 7px 7px 7px;
}

.four-zero,
.lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}
	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: $white;
		border-radius: 0 3px 3px 0;
		line-height: 36px;
	}
}

.p-15 {
	padding: 15px !important;
}

.action-header {
	position: relative;
	background: #f8f8f8;
	padding: 15px 13px 15px 17px;
}

.ah-actions {
	z-index: 3;
	float: right;
	margin-top: 7px;
	position: relative;
}

.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: #939393;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 10px;
	}
}

.ms-body {
	background: $white;
	border-radius: 7px;
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	cursor: pointer;
	text-align: right;
}

.message-feed.right {
	text-align: right;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}

.message-feed {
	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.job-box-filter {
	label {
		width: 100%;
	}
	select.input-sm {
		display: inline-block;
		max-width: 120px;
		margin: 0 5px;
		border: 1px solid $border;
		border-radius: 3px;
		height: 34px;
		font-size: 15px;
	}
	label input.form-control {
		max-width: 200px;
		display: inline-block;
		border: 1px solid $border;
		border-radius: 3px;
		margin-left: 5px;
		font-size: 15px;
	}
}

.job-box-filter {
	padding: 12px 15px;
	border-bottom: 1px solid $border;
}

.job-box {
	display: inline-block;
	width: 100%;
	padding: 0;
}

.job-box-filter {
	a.filtsec {
		margin-top: 8px;
		display: inline-block;
		margin-right: 15px;
		padding: 4px 10px;
		font-family: 'Poppins', sans-serif;
		transition: all ease .4s;
		background: #edf0f3;
		border-radius: 50px;
		font-size: 13px;
		color: #81a0b1;
		border: 1px solid $border;
		&.active {
			color: $white;
			background: #16262c;
			border-color: #16262c;
		}
		i {
			color: #03a9f4;
			margin-right: 5px;
		}
		&:focus,
		&:hover {
			color: $white;
			background: #07b107;
			border-color: #07b107;
		}
		&:focus i,
		&:hover i {
			color: $white;
		}
	}
	h4 i {
		margin-right: 10px;
	}
}

@media (max-width: 320px) {
	.perfect-datetimepicker {
		font-size: 12px !important;
	}
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending,
		&.unread {
			display: none;
		}
	}
}

#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			left: 0;
			opacity: .7;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			left: 1%;
			position: absolute;
			right: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: $white;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba(255, 255, 255, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 $muted, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.0980392), 0 0 5px rgba(0, 0, 0, 0.0980392), 0 1px 3px rgba(0, 0, 0, 0.298039), 0 3px 5px $black-2, 0 5px 10px rgba(0, 0, 0, 0.247059), 0 10px 10px $black-2, 0 20px 20px rgba(0, 0, 0, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: $white;
				font-size: 2em;
				margin-top: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: $white;
				font-size: 16px;
				font-weight: 300;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: $white;
					color: #333;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media (min-width: 569px) and (max-width: 992px) {
	#index-video .index-video-container .videocaption {
		top: 33%;
	}
	.item-card-img h3.text-white {
		font-size: 1rem;
	}
	.item-card2 h4.bg-light.p-3 {
		font-size: .8rem;
	}
}

@media (max-width: 448px) {
	#index-video .index-video-container .videocaption {
		top: 12%;
	}
}

.video-list-thumbs {
	>li {
		margin-bottom: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			padding: 8px;
			border-radius: 3px;
			transition: all .5s ease-in-out;
			border-radius: 4px;
		}
	}
	h2 {
		bottom: 0;
		font-size: 14px;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: 50px;
		opacity: 1;
		position: absolute;
		right: 0;
		left: 0;
		top: 35%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
		cursor: pointer;
	}
	>li>a:hover .fa {
		color: $white;
		opacity: 1;
		text-shadow: 0 1px 3px $black-8;
	}
	.duration {
		background-color: rgb(0, 0, 0);
		border-radius: 0;
		color: $white;
		font-size: 13px;
		font-weight: 700;
		left: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: $black;
	}
}

@media (max-width: 768px) {
	.video-list-thumbs span {
		top: 29%;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.video-list-thumbs {
		.fa {
			font-size: 35px;
		}
		h2 {
			bottom: 0;
			font-size: 12px;
			height: 22px;
			margin: 8px 0 0;
		}
	}
}

@media (max-width: 992px) {
	.navtab-wizard li a {
		padding: 1rem !important;
	}
	.search-background {
		background: 0 0;
		border-radius: 0;
		.form-group {
			margin-bottom: 10px !important;
		}
	}
	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}
	.product-singleinfo .border-start {
		border-left: 0 !important;
	}
	.support .border-end {
		border-right: 0 !important;
	}
	.support-service {
		&.bg-dark {
			margin-bottom: 10px;
		}
		margin-bottom: 10px;
	}
	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
		margin-left: 5rem !important;
	}
	.wideget-user-desc .user-wrap {
		margin-left: 10px !important;
	}
	.product-singleinfo .product-item2-align dt {
		width: 0 !important;
		float: none !important;
		display: -webkit-box !important;
	}
	.product-singleinfo .product-item2-align dd {
		margin-left: 0 !important;
	}
	.item-single .item-single-desc .item-single-desc1 {
		display: block !important;
	}
	.product-5-desc a {
		float: right;
	}
	.get-contact a:last-child {
		margin-right: 0;
	}
	.header-search .header-nav .nav-cart .icon-cart span {
		display: none;
	}
	.header-nav .header-search-logo .header-logo .header-brand-img {
		height: auto;
		line-height: 2rem;
		vertical-align: bottom;
		margin-right: .5rem;
		width: auto;
	}
	.header-search .header-search-logo {
		text-align: center;
	}
	.wideget-user .wideget-user-desc .user-wrap a.btn {
		margin-bottom: 5px;
	}
	.product-single {
		.product-thumbimg,
		.product-info a {
			margin-bottom: 10px;
		}
	}
	.item-single .item-single-desc .input-group {
		margin-bottom: 10px;
	}
	.product-item2-desc .label-rating {
		margin-right: 4px !important;
	}
	.card-blogs .card-item-desc.p-0 {
		margin-left: 15px;
	}
	.license-card-price.text-center {
		text-align: left !important;
	}
	.product-details td a.btn {
		margin-bottom: 5px;
	}
	.wideget-user-info .wideget-user-warap {
		margin-top: 15px;
	}
	.product-singleinfo .product-item2-desc {
		margin-bottom: 10px;
	}
	.header-search .header-inputs {
		.input-group-text,
		.input-group>.form-control {
			width: 100%;
			margin-bottom: 10px;
		}
		.input-group-text {
			width: 100%;
			margin-bottom: 15px;
			>.btn {
				width: 100%;
				display: block;
			}
		}
	}
	.header-main {
		.get-contact {
			a {
				border-left: 0 !important;
				padding-left: 0 !important;
			}
			margin-bottom: 15px;
		}
		.social-icons {
			text-align: center;
			float: none !important;
			li:last-child {
				margin-right: 0;
			}
		}
	}
	.header-search .header-icons {
		.header-icons-link li {
			margin: 0 auto !important;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-start .contact {
			border-left: 0 !important;
			margin-left: 0 !important;
			padding-left: 0 !important;
			li {
				margin: 0 !important;
			}
		}
	}
	.item-search-menu ul li {
		text-align: center;
		border-radius: 7px;
		a {
			border-radius: 7px !important;
		}
	}
	.item-search-tabs {
		.form-group {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 10px !important;
		}
		.bg-white {
			background: 0 0 !important;
		}
		.tab-content {
			background: 0 0 !important;
			border: 0 !important;
		}
	}
	.item1-card {
		.item1-card-btn,
		.item1-card-info {
			margin-top: 15px;
		}
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-bottom: 10px;
		a {
			display: block;
		}
	}
	.item2-gl .item2-gl-nav select,
	.item2-gl-nav h6 {
		text-align: center;
	}
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.search1 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}
	.search2 a.btn {
		margin-top: 15px;
	}
	.search3 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}
	.item-user-icons {
		margin-left: 10px;
	}
	.course-details {
		.item-user-icons {
			margin-left: 0px;
		}
	}
	.item1-card-tab .item1-tabs-menu ul li {
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.user-wideget h6 {
		margin-top: 5px;
	}
	.header-main .top-bar {
		.contact,
		&.p-3 .contact {
			display: none;
		}
	}
	.bannerimg .header-text h1 {
		display: block;
		margin-bottom: .5rem;
		font-size: 2rem;
	}
	.all-categories .row .card-body {
		border-bottom: 0 !important;
	}
	.ace-responsive-menu {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.item-card9-desc a,
	.item-card2-footer a,
	.item7-card-desc a {
		font-size: 12px;
	}
	.map-header1 {
		margin-bottom: 1.5rem !important;
	}
	.carousel-control-next {
		right: 0 !important;
	}
	.carousel-control-prev {
		left: 0 !important;
	}
	.app-btn a {
		width: 100%;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.sub-newsletter {
		margin-bottom: 1rem;
		text-align: center;
		display: block !important;
	}
	.fea-ad .card,
	.cat-slide .card {
		margin-bottom: 1.5rem !important;
	}
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 85px !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.usertab-list li {
		width: 100% !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul {
		li {
			a {
				padding: 8px 10px 8px 10px !important;
				text-align: center;
			}
			display: -webkit-box;
			text-align: center;
			margin-bottom: 1.5rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		text-align: center;
	}
	.settings-tab .tabs-menu li a {
		border-right: 0 !important;
		border-bottom: 1px solid rgba(167, 180, 201, 0.2);
		margin: 3px 4px !important;
	}
}

@media (max-width: 320px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-top: 4px !important;
	}
}

@media (min-width: 569px) and (max-width: 767px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-top: 4px !important;
	}
}

@media (min-width: 479px) and (max-width: 991px) {
	.page-header .form-control.page-select {
		width: 40% !important;
	}
}

@media (max-width: 568px) {
	.items-blog-tab-heading .items-blog-tab-menu li {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
	.header-main .top-bar-end .custom {
		text-align: right;
		float: right;
		margin-bottom: 0 !important;
	}
	.settings-tab .tabs-menu li {
		width: 100% !important;
	}
	.top-bar .top-bar-end {
		float: none !important;
		text-align: center;
		display: block !important;
		margin: 0 auto;
	}
	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-bottom: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
		}
	}
	.header-main .top-bar-end .custom li a span {
		display: none;
	}
	.top-bar .top-bar-start .socials li {
		float: left !important;
	}
	.item2-gl .item2-gl-nav select {
		width: 100% !important;
	}
}

@media (min-width: 992px) and (max-width: 1300px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 85px !important;
	}
}

@media (min-width: 481px) and (max-width: 991px) {
	.owl-product .owl-productimg {
		width: 100% !important;
		height: 100% !important;
		margin: 0 auto;
	}
	.art-wideget .art-details {
		margin-top: 10px;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -2px !important;
	}
	.user-wideget .widget-image {
		top: 50% !important;
	}
	.banner1 .header-text {
		h2 span,
		h3 span {
			padding: 0 !important;
		}
		top: 20% !important;
	}
	.item-card:hover .item-card-btn {
		left: 42%;
	}
	.product-img {
		margin: 0 auto;
		text-align: center;
	}
	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}
	.item-search-tabs {
		margin-top: 1rem !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.wideget-user-tab {
		.tabs-menu1 ul li a {
			padding: 5px !important;
		}
		.tab-menu-heading .nav li a {
			font-size: 14px !important;
		}
	}
	.header-search .header-search-logo {
		margin-top: 5px;
	}
	.header-main .post-btn {
		margin-top: 3px;
	}
	.course-details .wideget-user-tab .tabs-menu1 ul li a {
		padding: 11px 20px 12px 20px!important;
	}
}

@media (min-width: 992px) and (max-width: 1024px) {
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.header-main .get-contact {
		a {
			border-left: 0 !important;
			padding-left: 0 !important;
		}
		margin-bottom: 15px;
	}
	.header-search .header-inputs {
		margin-bottom: 15px;
	}
	.header-main .social-icons {
		text-align: center;
		float: none !important;
		li:last-child {
			margin-right: 0;
		}
	}
	.header-search .header-icons {
		.header-icons-link {
			float: right;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-start {
			text-align: center;
			.contact {
				border-left: 0 !important;
				margin-left: 0 !important;
				padding-left: 0 !important;
				margin: 12px 0 !important;
			}
		}
		.top-bar-end {
			float: none !important;
			text-align: center;
			display: block !important;
			margin: 0 auto;
		}
		.top-bar-start .contact li:first-child {
			margin-right: 1.5rem !important;
		}
	}
	.product-multiinfo .product-ship p a:first-child,
	.product-singleinfo .product-ship p a:first-child {
		margin-bottom: 5px;
	}
	.banner-2 img {
		height: 27rem;
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-bottom: 10px;
	}
	.ace-responsive-menu {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.header-main .top-bar-end .custom {
		display: -webkit-inline-box !important;
	}
	.icon-card li {
		font-size: 10px;
	}
	.support-service {
		&.bg-dark ,&.bg-success ,&.bg-primary ,&.bg-danger{
			margin-bottom: 10px;
		}
	}
	.support {
		.border-end {
			border-right:0 !important;
		}
	}
	.tab-content.item-list #tab-11 .item-card9-imgs img, 
	.tab-content.item-list #tab-11 .item-card2-img img {
		height: 299px;
	}
	.tab-content.item-list #tab-11 .icon-card-1 {
		display: grid;
	}
	
	.tab-content.item-list1 #tab-11 .item-card9-imgs img, 
	.tab-content.item-list1 #tab-11 .item-card2-img img {
		height: 310px;
	}
	.tab-content.item-list1 #tab-11 .icon-card-1 {
		display: grid;
	}
	
	.tab-content.item-list2 #tab-11 .item-card9-imgs img, 
	.tab-content.item-list2 #tab-11 .item-card2-img img {
		height: 320px;
	}
	.tab-content.item-list2 #tab-11 .icon-card-1 {
		display: grid;
	}
	.item-search-tabs .tab-content {
		margin: 0px 5px 0 0 !important;
	}
	.item-search-tabs .item-search-menu ul {
		padding: 0px 15px !important;
	}
	.tab-content.item-list1 #tab-11 {
		h3{
			white-space: nowrap;
		}
	}
	
	.tab-content.item-list2 #tab-11 {
		h3{
			white-space: nowrap;
		}
	}
	.item-list1 {
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	.item-list {
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	.item-list2 {
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	.cat-slide .card,
	.fea-ad .card {
		margin-bottom: 1.5rem !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.header-main .post-btn {
		margin-top: 5px;
	}
	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul li a {
		padding: 8px 10px 8px 10px !important;
	}
}
.tabs-menu1 ul li {
    margin: 0 2px;
}

@media (max-width: 480px) {
	.item-all-card img.imag-service {
		width: 20% !important;
	}
	.item-all-cat {
		.item-all-card img.imag-service {
			width: 40% !important;
		}
	}
	.section-title h1 {
		font-size: 2rem;
	}
	.item-all-cat .category-type .item-all-card img {
		width: 4rem !important;
		height: 4rem !important;
		padding: 1rem 0 !important;
	}
	.banner1 .header-text {
		h2 {
			span {
				padding: 0 !important;
			}
			font-size: 18px !important;
		}
		h3 {
			font-size: 14px !important;
			span {
				padding: 0 !important;
			}
		}
	}
	.card-blogs .card-item-desc.p-0 .card-item-wrap .footer-wrap-price {
		del {
			display: none;
		}
		span.h5 {
			font-size: 12px !important;
		}
	}
	.card-pay .tabs-menu li {
		width: 100% !important;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -1px !important;
	}
	.product-details .card-item-desc {
		display: none !important;
	}
	.art-wideget .art-details {
		margin-top: 10px;
	}
	.support .support-service {
		i {
			float: none !important;
			margin-right: 0 !important;
			margin-bottom: 20px;
		}
		text-align: center;
	}
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 12px;
	}
	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}
	.item-search-tabs {
		margin-top: 1rem !important;
	}
	.bannerimg .header-text {
		font-size: 1.5rem;
		.breadcrumb-item {
			font-size: 14px;
		}
	}
	.icons a {
		margin-bottom: .5rem;
	}
	.item-det ul li {
		font-size: 11px;
		margin-right: 0.5rem !important;
	}
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 60px !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.item-card7-desc {
		div{
			&.pt-2 {
				display: grid;
			}
		}
	}
}

@media (max-width: 992px) {
	.horizontal-header .container {
		position: relative;
	}
}

@media (max-width: 375px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 49px !important;
	}
}

@media (max-width: 320px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 40px !important;
	}
}

@media (max-width: 1336px) {
	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}
}

.all-categories .row {
	.card-body {
		border-bottom: 1px solid $border;
	}
	&:last-child .card-body {
		border-bottom: 0 !important;
	}
}

.clear,
.current,
.ok {
	background: #e9ecfb;
	border-radius: 20px;
}

.today {
	background: #e9ecfb;
}

.graph canvas {
	width: 100% !important;
}

.trend {
	i {
		font-size: 10px !important;
		vertical-align: middle;
	}
	.media-body {
		margin-left: 10px;
	}
}

.datebox {
	top: 0;
	left: 0;
	border-radius: 7px;
	background: $white;
	padding: 0 15px 0 15px;
}

.iconbage .badge {
	position: absolute;
	top: 14px;
	right: 13px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}

.single-product .product-desc .product-icons .socialicons a {
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #eef2f9;
	color: $color;
	margin: 0 auto;
	text-align: center;
}

.blogimg {
	width: 100px;
}

.subnews img {
	width: 25%;
	height: 25%;
}

.task-img img,
.status-img img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}

.product-hover {
	position: relative;
	.product-info-buttons {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
	}
	&:hover {
		&.product-hover:before {
			content: "";
			background: $black-5;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		.product-info-buttons {
			display: block;
			z-index: 1000;
		}
	}
}

.spacing {
	padding: 1.5rem;
}

.search-background,
.search1 {
	background: $white;
	border-radius: 7px;
	overflow: hidden;
}

.search2 {
	background: $white;
	padding: 30px;
	border-radius: 3px;
	overflow: hidden;
}

.search3 {
	background: $white;
	border-radius: 3px;
	a.btn {
		font-size: .8625rem;
	}
}

.product-single .product-thumbimg img {
	width: 130px;
	height: 130px;
}

.page-header .page-select {
	width: 20%;
}

.product-tags {
	a {
		float: left;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 3px;
		font-size: 12px;
		border: 1px solid $border;
	}
	li a:hover {
		border-radius: 3px;
		color: $white !important;
	}
}

.icon-bg i {
	font-size: 1.5rem;
}

.icon-service {
	display: inline-flex;
	width: 120px;
	height: 120px;
	text-align: center;
	border-radius: 7px;
	align-items: center;
	justify-content: center;
}

.box-shadow {
	box-shadow: 0px 0px 20px rgba(126, 142, 177, 0.5) !important;
}

.support-service {
	padding: 1.5rem 1.5rem;
	background: #f2f3f8;
	border-radius: 7px;
	i {
		float: left;
		margin-right: 15px;
		font-size: 1.5rem;
		line-height: 40px;
		width: 45px;
		text-align: center;
		height: 45px;
		border-radius: 50%;
		background: $white-3;
		border: 1px solid $white-3;
	}
	h6 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
		font-size: 15px;
		color: $white-5;
	}
}

.counter-icon {
	margin-bottom: 1rem;
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 5px;
    text-align: center;
    font-size: 2rem;
	i {
		font-size: 2rem;
		margin: 0 auto;
	}
}

.get-contact {
	a {
		color: #212529;
		margin-right: 15px;
		font-size: 16px;
		display: inline-block;
	}
	i {
		font-size: 18px;
	}
}

.filter-product-social {
	.social-icon2 li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
	}
	.social-icons li a {
		color: #212529;
	}
}

.rated-products {
	.media {
		padding-bottom: 15px;
		border-bottom: 1px solid $border;
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		img {
			width: 80px;
			height: 80px;
			border-radius:7px;
		}
	}
	img {
		border: 1px solid $border;
		padding: 10px;
		background: $background;
	}
}

.product-info-img a {
	position: absolute;
	top: 35px;
	right: 30px;
}

.product-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-bottom: 15px;
	.product-filter-img {
		width: 60px;
		height: 60px;
	}
}

.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.4em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	&:hover {
		color: $white;
	}
}

.usertab-list {
	display: inline-block;
	li {
		width: 50%;
		margin-bottom: .6rem;
		float: left;
		font-size: 15px;
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black-2;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 3px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}

.product-item {
	    background: $primary-background;
	    height: 90px;
	    width: 90px;
	    margin: 0 auto;
	    border-radius: 50%;
	img {
   		text-align: center; 
		padding: 20px;
	}
	span {
		top: 15px;
		left: 15px;
		position: absolute;
	}
}

.product-item-wrap .product-item-price {
	.newprice {
		font-size: 18px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 600;
	}
}

.product-item1 {
	position: relative;
	.item-overlay {
		border-radius: .2rem .2rem 0 0;
		overflow: hidden;
		padding: 15px;
		text-align: center;
		img {
			height: 200px;
			width: 200px;
		}
	}
	.btn-overlay {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		z-index: 1;
	}
	&:hover.product-item1:before {
		content: "";
		background-color: $white-8;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}

.btn-overlay span {
	display: block;
	margin: .5rem;
	font-size: 16px;
	font-weight: 600;
}

.icon-card {
	display: inline-block;
	li {
		float: left;
		font-size: 13px;
		width: 50%;
		margin-bottom: .5rem;
	}
}

.product-item1:hover .btn-overlay {
	display: block;
	z-index: 1;
	opacity: 1;
}

.product-item2 {
	.product-item2-img {
		img {
			height: 200px;
			width: 200px;
		}
		padding: 1.5rem 1.5rem;
	}
	border-bottom: 1px solid $border;
}

.product-item2-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-item2 {
	border-radius: 7px 7px 0 0;
	overflow: hidden;
}

.owl-productimg img {
	background: #e7e9f1;
	padding: 20px;
}

.product-item2-desc .label-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

.product-details {
	.media {
		margin-top: 0;
	}
	.card-item-desc {
		padding: 0;
		margin-left: 15px;
		margin-top: 8px;
	}
}

.product-singleinfo .product-item2-align dt,
dd {
	display: inline-block;
}

.product-singleinfo {
	.product-item2-align {
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
		dt {
			width: 100px;
			float: left;
		}
	}
	dl {
		margin-bottom: 5px;
	}
}

.oldprice {
	font-size: 25px;
	font-weight: 600;
}

.product-singleinfo .product-ship .product-item-price {
	.newprice {
		font-size: 25px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
	}
}

.product-multiinfo {
	.card {
		box-shadow: none;
		margin-bottom: 0;
		border-bottom: 1px solid $border;
	}
	.product-item img {
		width: 120px;
		height: 120px;
	}
}

.single-productslide .product-gallery .product-item {
	margin-bottom: 10px;
	overflow: hidden;
	background-color: $white;
}

.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 3px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid $border;
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}

.single-productslide .product-gallery-data {
	padding: 30px;
}

.product-gallery-data .product-gallery-data1 dt,
dd {
	display: inline-block;
}

.product-gallery-data {
	dl {
		margin-bottom: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: left;
		}
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
	}
}

.product-gallery-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-gallery-rats {
	margin-top: 20px;
}

.product-gallery-data {
	.label-rating {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}

dd,
.product-gallery-data .product-gallery-size dt,
dd {
	display: inline-block;
}

.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-left: 10px;
}

.product-card-img img {
	height: 180px;
	width: 180px;
}

.product-card-icons {
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	display: inline-block;
}

.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-left: 5px;
	}
}

.product-card4-footer .product-card4-wrap .product-card4-price h6,
.product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}

.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-right: 5px;
	}
}

.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del,
	span {
		margin-left: 5px;
	}
}

.team-section .team-img img {
	max-width: 150px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.checklist-task .checklist-desc .check-data {
	span {
		font-size: 13px;
	}
	.check-icon {
		margin-top: 0;
	}
}

.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left,
		&.right {
			opacity: 1;
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span,
		&.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left,
		.glyphicon-chevron-right,
		.icon-next,
		.icon-prev {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left,
		.icon-prev {
			left: 0;
		}
		.glyphicon-chevron-right,
		.icon-next {
			right: 0;
		}
		&.left span,
		&.right span {
			background: $white-1;
			color: $white;
		}
		&.left span:hover,
		&.right span:hover {
			background: $white-3;
		}
	}
	.header-text {
		position: absolute;
		left: 0;
		right: 0;
		color: $white;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.banner1 .slider {
		.header-text {
			top: 35% !important;
		}
		img {
			height: 550px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.banner1 .slider {
		.header-text {
			top: 32% !important;
		}
		img {
			height: 570px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1240px) {
	.banner1 .slider {
		.header-text {
			top: 23%!important;
		}
		img {
			height: 650px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1366px) {
	.banner1.banner-top .slide-header-text {
		top:0;
	}
	.item-list1 .item-card2-desc ul li {
		width: auto; 
	}
	
	.item-list2 .item-card2-desc ul li {
		width: auto; 
	}
}
.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}

.cat-item {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.cat-img img {
		width: 60px;
		height: 60px;
		margin: 0 auto;
	}
	.cat-img svg {
		width: 45px;
		height: 45px;
		margin: 0 auto;
		opacity:1;
	}
	.cat-desc {
		margin-top: 15px;
		color: #212529;
	}
}
#owl-demo2 {
	.cat-item {
		.cat-img {
		    background: rgba(105, 100, 247, 0.15);
		    width: 100px;
		    height: 100px;
		    text-align: center;
		    border-radius: 100%;
		    margin: 0 auto;
		    padding:24px;
		}
		
		.cat-img svg {
			width: 55px;
			height: 55px;
			margin: 0 auto;
			opacity:1;
		}
		.cat-desc {
			.badge-pill {
			    padding-right: 1.6em;
			    padding-left: 1.6em;
			    padding: .6rem 1.2rem;
			    border-radius: 10rem;
			}
		}
	}
}
.bg-card-light:hover .cat-item .cat-img svg{
	opacity:1;
}

.about {
	img {
	width: 60px;
	height: 60px;
	text-align: center;
	}
	svg{
		width: 70px;
		height: 70px;
		text-align: center;
	}
}
.modal{
	.about {
		width: 120px;
		height: 120px;
		line-height: 120px;
		border-radius:50%;
		margin:0 auto;
		position:relative;
		&:before{
			content:'';
			position:absolute;
			width: 140px;
			height: 140px;
			line-height: 140px;
			border-radius:50%;
			margin:0 auto;
			top: -10px;
			left: -10px;
		}
		&:after{
			content:'';
			position:absolute;
			width: 160px;
			height: 160px;
			line-height: 160px;
			border-radius:50%;
			margin:0 auto;
			top: -20px;
			left: -20px;
		}
		svg{
			width: 70px;
			height: 70px;
			text-align: center;
		}
	}
}
.Category .item-card .item-card-desc .item-card-text {
	position: absolute;
	top: 40%;
}

.business-item .item-card .item-card-desc .item-card-text {
	top: 40%;
}

@media (max-width: 1300px) and (min-width: 400px) {
	.classifieds-content .item-search-menu ul li a,
	.item-search-menu ul li a {
		display: block;
		margin: 3px;
		border-radius: 3px;
	}
}

.accent-2 {
	width: 60px;
}

.Marketplace {
	.item-card7-imgs:before {
		background: rgba(25, 36, 79, 0.1);
	}
	.item-card7-overlaytext h4 {
		font-size: 18px;
		border-radius: 3px;
		padding: 7px 12px;
		text-align: center;
		bottom: 5px;
		right: 5px;
		background: $black-8;
	}
}

@media (min-width: 992px) {
	.tab-content #tab-11 {
		.item-card9-img {
			width: 400px;
		}
		.item-card9-imgs img,
		.item-card2-img img {
			height: 209px;
		}
	}
	.h-197 {
		height: 197px;
	}
	.navtab-wizard .nav-item{
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}
}

@media (max-width: 991px) and (min-width:767px) {
	.tab-content #tab-11 {
		.item-card9-img {
			width: 400px;
		}
		.item-card9-imgs img,
		.item-card2-img img {
			height: 319px;
		}
	}
	.h-197 {
		height: 197px;
	}
}

.product-slider {
	#carousel {
		margin: 0;
	}
	#thumbcarousel {
		margin: 10px 0 0 0;
		padding: 0;
		.carousel-item {
			text-align: center;
			.thumb {
				width: 100%;
				margin: 0 2px;
				display: inline-block;
				vertical-align: middle;
				cursor: pointer;
				max-width: 137px;
				&:hover {
					border-color: $border;
				}
			}
		}
	}
	.carousel-item img {
		width: 100%;
		height: auto;
	}
}

#thumbcarousel {
	.carousel-control-prev {
		left: 0;
		background: $black-5;
	}
	.carousel-control-next {
		right: 0;
		background: $black-5;
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.sptb {
	padding-top: 2.5rem;
	padding-bottom: 5rem;
}

.sptb-1 {
	padding-top: 8.5rem;
	padding-bottom: 4.5rem;
}

.sptb-2 {
	padding-top: 16rem;
	padding-bottom: 14rem;
}

.sptb-3 {
	padding-top: 6.2rem;
	padding-bottom: 11rem;
}

.sptb-4 {
	padding-top: 11rem;
	padding-bottom: 11rem;
}

.sptb-8 {
	padding-top: 7rem;
	padding-bottom: 12rem;
}

.categories-1 {
	margin-top: -120px;
}

.section-title {
	p {
		font-size: 16px;
		margin-bottom: 0;
	}
	padding-bottom: 3rem;
	h1 {
		margin-bottom: .5rem;
		color: $color;
		font-size: 36px;
		font-weight: 600;
		z-index: 1;
		font-family: 'Poppins', sans-serif;
	}
	h2 {
		font-family: 'Poppins', sans-serif;
		font-size: 2rem;
		font-weight: 600;
	}
}

.section-title2 {
	p {
		font-size: 16px;
		margin-bottom: 0;
	}
	padding-bottom: 2rem;
	h1 {
		margin-bottom: .5rem;
		color: $color;
		font-size: 36px;
		font-weight: 400;
		z-index: 1;
		font-family: 'Poppins', sans-serif;
	}
}

.servic-data {
	h4 {
		font-size: 19px;
	}
	p {
		font-size: 14px;
		line-height: 21px;
	}
}

@media (min-width: 992px) {
	.typewrite-text {
		.select2-container .select2-selection--single {
			border-radius: 7px 0 0 7px !important;
			border-right: 0 !important;
		}
		.form-control.input-lg {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
	.slider-images img {
		height: 400px !important;
		background: cover;
		left: 0;
		right: 0;
		width: 100% !important;
	}
	.banner1 .slider .header-text {
		top: 29%;
	}
	.classified-video {
		margin-top: 2.1rem;
	}
}

@media (min-width: 991px) and (max-width: 1024px) {
	.input-field {
		margin-top: 12rem !important;
	}
	.details2 .wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li a {
		margin: 0px !important;
	}
}

@media (max-width: 991px) {
	.input-field {
		margin-top: 13.55rem !important;
	}
	.select2-container .select2-selection--single {
		border-radius: 7px !important;
	}
	.sptb-4,
	.sptb-3 {
		padding-top: 0rem;
		padding-bottom: 10rem;
	}
	.banner-1 .item-search-tabs .search-background .form-control {
		border-radius: 3px !important;
		border-bottom: 1px !important;
	}
	.item-card9-img {
		height: auto !important;
	}
	.blog-list-1 {
		height: 100% !important;
	}
	.banner-1 .search-background {
		.form-control {
			border-radius: 7px !important;
		}
		.btn.btn-xl {
			border-radius: 7px !important;
		}
		.form1 {
			border-radius: 0;
			border-left: 1px solid $border !important;
		}
	}
	.item-search-tabs a.btn {
		border-top-left-radius: 7px !important;
		border-bottom-left-radius: 7px !important;
		border-radius: 7px !important;
		right: 0;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
	.counter-status.status {
		margin-top: 1.5rem;
	}
	.margin-top {
		margin-top: 4.5rem !important;
	}
	.desktoplogo-1 {
		display: none;
	}
	.sptb-1 {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}
	.search-background {
		background: 0 0 !important;
		border-radius: 0;
	}
	.padding-bottom {
		padding-bottom: 0 !important;
	}
	.banner-2 .form-control,
	.item-search-tabs .form-control.border {
		border-radius: 7px !important;
	}
	.banner-1 .search-background .btn-lg {
		border-radius: 7px !important;
		right: 0 !important;
	}
	.sptb-2 {
		padding-top: 0;
		padding-bottom: 9rem;
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			background: 0 0;
		}
		.desktoplogo-1 {
			display: none !important;
		}
	}
	.bannerimg {
		padding: 3rem 0 3rem 0 !important;
		background-size: cover;
	}
	.sticky-wrapper {
		height: 0 !important;
	}
	.select2.select2-container.select2-container--default.select2-container--focus {
		overflow: hidden;
	}
	.customerpage {
		.nav-tabs .nav-link {
			width: auto;
		}
	}
}

.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
	color: $color !important;
}

.sticky-wrapper.absolute.banners {
	.horizontal-main {
		background: $white;
		.horizontalMenu>.horizontalMenu-list>li>a {
			color: $color !important;
			>.fa {
				color: $color !important;
			}
		}
	}
	.horizontalMenu>.horizontalMenu-list>li {
		&:hover>a .fa,
		>a.active .fa {
			color: $white !important;
		}
	}
}

.banner-1 {
	.item-search-tabs .search-background .form-control {
		border-radius: 7px 0 7px 0;
		border-bottom: 0 !important;
	}
	.search-background {
		.form-control {
			border-radius: 7px 0 0 7px;
			border-right: 0;
		}
		.form1 {
			border-radius: 7px 0 7px 0;
			border-left: 1px solid $border !important;
		}
		.btn-xl {
			border-radius: 0 7px 7px 0 !important;
			position: relative;
		}
		.select2-container--default .select2-selection--single {
			border-radius: 0;
			border-right: 0 !important;
		}
	}
	.header-text,
	.header-text1 {
		left: 0;
		right: 0;
	}
	.header-text h1 {
		margin-bottom: .3rem;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
	}
}

.header-text h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
}

.banner-1 .header-text p {
	margin-bottom: 1.5rem;
	font-size: 16px;
}

.banner-2 {
	.form-control {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		border: 0 !important;
	}
	.header-text {
		left: 0;
		right: 0;
		color: $white;
		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
}

.bannerimg {
	padding: 6rem 0 3rem 0;
	background-size: cover;
	.header-text h1 {
		margin-bottom: .75rem;
	}
}

.about-1 {
	position: relative;
	.header-text {
		left: 0;
		right: 0;
		color: $white;
	}
}

.country .dropdown-menu {
	height: 233px;
	overflow: scroll;
}

.product-slider {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		padding: .5rem;
	}
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: .5rem;
	}
}

.support-list li {
	a {
		margin-bottom: .5rem;
		display: flex;
	}
	i {
		margin-right: .7rem;
		margin-top: 4px;
	}
}

.profile-pic-img {
	position: relative;
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
	text-align: center;
	margin: 0 auto;
	span {
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50px;
		right: -1px;
		top: .5rem;
		border: 2px solid $white;
	}
}

.sub-newsletter {
	display: inline-block;
}

.user-tabel span {
	margin: 0 auto;
}

.settings-tab .tabs-menu {
	margin-bottom: 25px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: 10px 20px 11px 20px;
			background: #f2f3f8;
			color: $black;
			display: block;
			text-align: center;
			border-radius: 7px;
			margin: 0 4px;
			&.active {
				color: $white;
				border-radius: 7px;
				text-align: center;
			}
		}
	}
}
.my-favadd ,.manged-ad{
	.media-body h4 {
		font-size: 14px;
	}
	.media-body a {
		font-size: 12px;
    		color: $muted;
	}
	.card-aside-img img {
		width: 80px !important;
		height: 80px !important;
		border-radius: 7px;
	}
	.media-body {
		margin: auto;
	}
	.table td {
		vertical-align: middle;
	}
}
.dataTables_paginate .pagination .next:hover {
	transform: none;
}
.data-table-example  {
    border-radius: 7px;
    overflow: hidden;
}
.payments {
	.tabs-menu1 {
		ul li {
			&:first-child a{
				margin-left:0;
			}
			a {
				padding: 10px 20px 11px 20px;
				border-radius: 7px;
				margin:0 4px;
				background:$background;
				border: 1px solid $background;
				color: $color;
				text-align: center;
				&.active {
					color: $white;
				}
			}
	    }
    } 
}
.my-orders {
	.tabs-menu1 {
		ul li {
			width:32.9%;
			a {
				padding: 10px 20px 11px 20px;
				border-radius: 7px;
				margin:0 4px;
				background:$background;
				border: 1px solid $background;
				color: $color;
				text-align: center;
				&.active {
					color: $white;
				}
			}
	    }
    } 
}
.ads-tabs {
	.tabs-menus {
		margin-bottom: 2rem;
		ul li {
			margin-right: 1rem;
			&:last-child {
				margin-right: 0;
			}
			a {
				padding: 10px 20px 11px 20px;
				border-radius: 7px;
				background:$background;
				color: $color;
			}
		}
	}
	.media-body h4 {
		font-size: 14px;
	}
	.media-body a {
		font-size: 12px;
		color: $muted;
	}
	.card-aside-img img {
		width: 80px !important;
		height: 80px !important;
		border-radius: 7px;
	}
	.media-body {
		margin: auto;
	}
	.table td {
		vertical-align: middle;
	}
}

.select-languages {
	color: $color;
	border-radius: 3px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
}

.img-flag {
	width: 25px;
	height: 12px;
	margin-top: -4px;
}

#price {
	border: 0;
	font-weight: 500;
	font-size: 18px;
}

.showmore-button {
	text-align: center;
}

.showmore-button-inner {
	display: inline-block;
	padding: 10px 19px;
	line-height: 14px;
	font-size: 14px;
	border-radius: 25px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 20px;
}

.showmore-button1 {
	text-align: center;
	.showmore-button1-inner {
		display: inline-block;
		line-height: 14px;
		font-size: 14px;
		text-decoration: none;
		cursor: pointer;
		margin-top: 8px;
		margin-bottom: 20px;
	}
}

.description p {
	font-size: 15px;
	line-height: 22px;
}

.blog-list {
	p {
		font-size: 15px;
		line-height: 21px;
	}
	h3 {
		font-size: 1.3rem !important;
	}
}

.blog-grid {
	p {
		font-size: 15px;
		line-height: 22px;
	}
	h3 {
		font-size: 1.3rem !important;
	}
}

.blog-detail p {
	font-size: 15px;
	line-height: 22px;
}

.fea-ad .card,
.cat-slide .card {
	margin-bottom: 0;
}

.fade.in {
	opacity: 1;
}

.owl-carousel {
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../plugins/owl-carousel/cursor.png), move;
		overflow: hidden;
	}
}

.owl-nav {
	display: block;
}

#small-categories .owl-nav {
	.owl-next,
	.owl-prev {
		top: 58%;
	}
}

.slider .owl-nav {
	.owl-prev {
		left: 15px;
	}
	.owl-next {
		right: 15px;
	}
}

.owl-nav {
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -25px;
		right: -1.5em;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: -25px;
		margin-top: -1.65em;
	}
}

.slide-banner .owl-nav {
	.owl-prev,
	.owl-next {
		top: 63%;
	}
}

@media (max-width: 480px) {
	.owl-nav {
		.owl-prev {
			left: -10px;
		}
		.owl-next {
			right: -10px;
		}
	}
	.countdown-timer-wrapper {
		.timer .timer-wrapper .time {
			width: 70px;
			height: 70px;
			font-size: 1.3rem !important;
			margin-bottom: -25px;
		}
		margin: 39px auto 15px auto !important;
	}
	.categories-1 .banner-1 .header-text p {
		margin-bottom: .5rem;
		font-size: 14px;
	}
}

.owl-nav button {
	display: block;
	font-size: 1.3rem !important;
	line-height: 2em;
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	text-align: center;
	background: $white-8 !important;
	border: 1px solid $border !important;
	z-index: 99;
	box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #2098d1;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: .5s;
		transition-duration: .5s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
}

.owl-carousel:hover .owl-nav button {
	background: rgb(255, 255, 255) !important;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	animation: sonarEffect 1.3s ease-out 75ms;
}

.owl-nav>div i {
	margin: 0;
}

.owl-theme .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	bottom: .65em;
	left: 0;
	right: 0;
	z-index: 99;
	.owl-dot {
		display: inline-block;
		zoom: 1;
		span {
			width: 1em;
			height: 1em;
			margin: 5px 7px;
			background: $black-3;
			display: block;
			-webkit-backface-visibility: visible;
			transition: opacity .2s ease;
			border-radius: 30px;
		}
	}
}

.testimonial-owl-carousel img {
	margin: 0 auto;
	text-align: center;
}

.center-block {
	float: none;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.owl-carousel.owl-drag .owl-item {
	left: 0 !important;
	right: 0;
	padding:15px !important;
}
.bannner-owl-carousel.owl-carousel.owl-drag .owl-item, .client-carousel.owl-carousel.owl-drag .owl-item{
	padding:0 !important;
}
.classes-carousel.owl-carousel.owl-drag .owl-item{
	padding:0 10px !important;
	a:hover{
		color:$color;
		background:#f7f8fd;
	}
	.icon-circle-style{
		width:50px;
		height:50px;
		line-height:50px !important;
		border-radius:7px 0 0 7px;
		display:inline-block;
		text-align:center;
	}
}
.wishlist {
	background: $color;
	&.active {
		background: #e62a72;
	}
}

.constuction-logo {
	border-bottom: 3px solid;
	border-radius: 3px;
}

.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 3px;
}

.eductaional-tabs.nav {
	display: flex;
	border-bottom:1px solid $border;
}

@media (max-width: 767px) {
	.tabs-menu ul.eductaional-tabs li {
		width: 50%;
		text-align: center;
	}
	.icon-card li {
		&.mb-0 {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 0.5rem !important;
		}
		width: 100% !important;
	}
	.item-cards7-ic li {
		width: 100% !important;
	}
	.item-card7-desc ul.d-flex li {
		margin-bottom: 10px;
		width: 100% !important;
	}
	.counter-status.status-1 {
		margin-top: 1.5rem;
	}
	.item-card7-desc ul.d-flex {
		display: block !important;
	}
	.tabs-menu ul.eductaional-tabs li a {
		display: block;
	}
	.item-card2-desc ul.d-flex li {
		margin-bottom: 10px;
	}
	.item-card2-desc ul.d-flex {
		display: block !important;
	}
}

.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
	background: #fb9512;
}

@media (max-width: 768px) {
	.tabs-menu1 ul li a {
		display: block;
		width: 100%;
		border: 1px solid $border;
		margin: 1px;
		border-radius: 3px;
	}
	.wideget-user-tab .tabs-menu1 ul li .active {
		border: 1px solid $border;
		border-radius: 3px;
	}
}

.profile-pic {
	text-align: center;
	.d-md-flex {
		text-align: left;
	}
}

@media (max-width: 480px) {
	.d-flex.ad-post-details {
		display: block !important;
	}
	.tabs-menu1 ul li a {
		margin: 2px !important;
	}
	#commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li a {
	    margin: 0 !important;
	}
	.list-id .col {
		display: block;
		width: 100%;
	}
	.ads-tabs .tabs-menus ul li {
		a {
			width: 100%;
			display: block;
		}
		margin-bottom: 10px;
		margin-right: 0;
		width: 100%;
	}
	.zoom-container .arrow-ribbon2 {
		font-size: 20px;
		&:before {
			right: -17px;
		}
	}
}

@media (min-width: 561px) and (max-width: 767px) {
	.navtab-wizard.nav-tabs li {
		width: 100%;
	}
	.tabs-menu1 ul li {
		display: block;
		width: 49%;
		margin: 1px;
	}

	.wideget-user-tab .tabs-menu1 ul li {
		width: auto;
		margin: 0 6px 0 0;
	}

	.navtab-wizard.nav-tabs .nav-link {
		&:first-child {
			border-top: 0;
		}
		border-left: 0;
		border-top: 1px solid $border;
	}
	.profile-2 .wideget-user-tab.wideget-user-tab3 {
		.tabs-menu1 ul li {
			width: 100%;
			a{
				padding: 15px !important;
			}
		}
		margin-bottom: 2rem;
	}
}

.owl-carousel {
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#carousel-controls.owl-carousel .owl-item img,
#exzoom {
	width: 100%;
}

:focus {
	outline: 0 !important;
}

#defaultCarousel .item p,
.news p {
	font-size: 16px;
	line-height: 21px;
}

.marketplace-or {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 20px;
	line-height: 56px;
	text-align: center;
	margin: 0 auto;
	border: 3px solid rgba(255, 162, 43, 0.1);
	background: rgba(255, 162, 43, 0.3);
}

.marketplace-alignment {
	.text-center {
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.ecommerce-menu,
.header-menu1 {
	float: none !important;
}

@media (max-width: 340px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 767px) and (min-width: 569px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 1024px) and (min-width: 992px) {
	.auction-content .product-item2-desc .product-item2-rating,
	.owl-carousel-icons6 .product-item2-desc .product-item2-rating {
		display: block;
	}
}

.ecommerce-menu.horizontalMenu>.horizontalMenu-list>li>a {
	padding: 9px 30px;
	.fa {
		margin-right: 9px;
	}
}

@media (min-width: 992px) and (max-width: 1350px) {
	.ecommerce-menu.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 9px 15px;
	}
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	#container1 {
		min-height: 460px !important;
	}
}

@media (max-width: 567px) {
	#container1 {
		min-height: 515px !important;
	}
}

@media (max-width: 992px) {
	.header-menu1 {
		.input-group {
			padding: 10px !important;
		}
		a.btn {
			width: 220px;
			text-align: center !important;
			margin: 0 auto !important;
			float: none !important;
		}
	}
	.testimonial-owl-carousel .owl-nav {
		.owl-prev,
		.owl-next {
			top: 20% !important;
		}
	}
}

.register-right .nav-tabs {
	.nav-link {
		padding: 12px 25px;
		margin: 1px;
		text-align: center;
		display: block;
		border-radius: 7px;
	}
	background: $white;
	margin: 0 auto;
	border-radius: 7px;
	box-shadow: 0 10px 40px 0 rgba(62,57,107,0.1),0 2px 9px 0 rgba(62,57,107,0.1);
	.nav-item {
		display: block;
		text-align: center;
	}
}

.gradient-icon {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.header-text p {
	font-size: 17px;
}

.product-item2-desc p {
	font-size: 15px;
	line-height: 21px;
}

@media (max-width: 320px) {
	.Marketplace .tab-pane .card-footer .footerimg-r {
		display: none;
	}
}

#homeVideo button.btn.btn-default {
	background: rgba(34, 40, 74, 0.7);
	position: absolute;
	right: 5px;
	top: 5px;
	border-radius: 25px;
	padding: 6px 3px;
	line-height: 25px;
	z-index: 5;
	color: $white;
	border:0;
	&:hover {
		background: rgb(34, 40, 74);
	}
}

.vertical-scroll {
	.news-item {
		border: 1px solid $border !important;
		margin-bottom: 15px;
		padding: 10px;
		border-radius: 7px;
		td:last-child {
			width: 76%;
		}
	}
	.item {
		border: 1px solid $border !important;
		margin-bottom: 10px;
		margin-top: 10px;
		border-radius:7px;
		overflow: hidden;
		.p-5 {
			padding: 1rem !important;
		}
	}
	margin-bottom: 0;
}

.vertical-scroll1 .item {
	border: 1px solid $border !important;
	margin-bottom: 15px;
	background: $white;
	a {
		text-decoration: intial;
	}
}

.vertical-scroll .item2 {
	border-bottom: 1px solid $border !important;
	padding: 12px 25px;
}

@media (max-width: 480px) {
	.employers-details {
		display: none;
	}
	.hide-details {
		height: 128px !important;
	}
}

@media (max-width: 400px) {
	.employers-btn,
	.distracted {
		display: none;
	}
}

.pt-10 {
	padding-top: 8rem !important;
}

.axgmap {
	position: relative;
	width: 100%;
	height: 500px;
}

.axgmap-img {
	width: 30px;
	height: 30px;
	text-align: center;
	background: $white;
}

.gm-style .gm-style-iw {
	padding: 20px;
	overflow: hidden;
	display: block;
	margin: 0 auto;
	padding-bottom: 0;
	text-align: center;
	width: 250px !important;
	h4 {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}
}

.gm-ui-hover-effect {
	opacity: .6;
	top: 0 !important;
	right: 0 !important;
}

.gm-style .gm-style-iw a.btn {
	margin-bottom: 6px;
}

@media (max-width: 480px) {
	.reviews-categories .widget-spec1 li {
		margin-bottom: .5rem;
		font-size: .9rem;
		display: flow-root;
		span {
			margin: 0;
		}
	}
}

@media (max-width: 767px) {
	.books-categories.item-all-cat .row .item-all-card,
	.doctor-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}
	.rating-stars .rating-stars-container .rating-star.sm {
		font-size: 13px;
	}
}

@media (max-width: 568px) {
	.items-gallery .items-blog-tab-heading {
		padding: 15px 0;
	}
	.items-blog-tab-heading .items-blog-tab-menu li a {
		margin-left: 0;
		margin-right: 0;
	}
	.items-gallery .items-blog-tab-heading {
		display: block;
	}
	.books-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}
}

.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
	background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: $color;
		&.btn {
			color: $color;
			font-size: .85rem;
		}
		>.fa,
		&.active .fa {
			color: $white;
		}
	}
	&:hover>a>.fa {
		color: $white;
	}
	> {
		a:hover .fa {
			color: $white;
		}
		ul.sub-menu>li {
			a i {
				color: #333;
			}
			&:hover>a i {
				color: $white;
			}
		}
	}
}

code {
	font-size: 16px;
	background: $border;
	padding: 3px 5px;
	border-radius: 7px;
	border: 1px solid $border;
}

button#ihavecookiesBtn {
	margin-left: 0 !important;
}

#gdpr-cookie-message {
	position: fixed;
	z-index: 1003;
	left: 0;
	bottom: 30px;
	max-width: 375px;
	background-color: rgba(255, 255, 255, 0.95);
	padding: 20px;
	border-radius: 3px;
	border: 1px solid $border;
	box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.6);
	margin-left: 30px;
	text-align: center;
	display: none;
	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h5 {
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	p,
	ul {
		color: $color;
		font-size: 15px;
		line-height: 1.5em;
	}
	p:last-child {
		margin-bottom: 0;
		text-align: center;
	}
	li {
		width: 49%;
		display: -webkit-inline-box;
	}
	a {
		text-decoration: none;
		font-size: 15px;
		padding-bottom: 2px;
		transition: all .3s ease-in;
	}
	button {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 3px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
}

button {
	&#ihavecookiesBtn {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 3px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
	&#gdpr-cookie-advanced {
		margin-left: 4px;
	}
}

@media (max-width: 390px) {
	button#gdpr-cookie-advanced {
		margin-left: 0 !important;
	}
	#gdpr-cookie-message p button {
		width: 100%;
	}
}

@media (max-width: 480px) {
	#gdpr-cookie-message {
		margin: 0 15px;
		display: block;
		right: 0;
		box-shadow: 0 0 20px 0 rgba(40, 37, 89, 0.9);
		display: none !important;
	}
}

#gdpr-cookie-message {
	button:disabled {
		opacity: .3;
	}
	input[type=checkbox] {
		float: none;
		margin-top: 0;
		margin-right: 5px;
	}
}

.marketplace-section img {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	padding: 2.3rem 0;
}

.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: 700;
	a {
		color: #e1e463;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid $border;
	font-weight: 700;
	color: #333;
}

#particles-js {
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	z-index: 1;
}

.relative {
	position: relative !important;
}

.zindex1 {
	z-index: 1 !important;
}

.zindex2 {
	z-index: 2 !important;
}

h1.animated-text {
	font-family: monospace;
	overflow: hidden;
	border-right: .15em solid orange;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

@keyframes blinking-cursor {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: orange;
	}
}

.banner1 .slide-header-text {
	top: 9%;
	z-index: 98 !important;
}

.slider-header .item:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	display: block;
	z-index: 1;
	top: 0;
}

a.typewrite {
	color:$white !important;
	font-size: 2.5rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}

.btn-floating {
	&.btn-sm i {
		font-size: .96154rem;
		line-height: 36.15385px;
	}
	i {
		display: inline-block;
		width: inherit;
		text-align: center;
		color: $white;
	}
	width: 35px;
	height: 35px;
	line-height: 1.7;
	position: relative;
	z-index: 1;
	vertical-align: middle;
	display: inline-block;
	overflow: hidden;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	padding: 0;
	cursor: pointer;
	background: $white-1;
}
.social-icons {
	&.btn-sm i {
		font-size: .96154rem;
		line-height: 36.15385px;
	}
	i {
		display: inline-block;
		width: inherit;
		text-align: center;
		color: $white;
	}
	width: 35px;
	height: 35px;
	line-height: 1.7;
	position: relative;
	z-index: 1;
	vertical-align: middle;
	display: inline-block;
	overflow: hidden;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-border-radius: 50%;
	border-radius: 5px;
	padding: 0;
	cursor: pointer;
	background: rgba($white, 0.05);
}
.pattern {
	background: url(../images/patterns/5.png);
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
}

.pattern-1 {
	&:before {
		background: linear-gradient(-225deg, rgba(72, 1, 255, 0.7) 0, rgba(121, 24, 242, 0.7) 48%, rgba(172, 50, 228, 0.7) 100%);
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
	background: url(../images/media/img01.jpg) center;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	overflow: hidden;
}
.pattern-2 {
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
	background: url(../images/patterns/circle4.png) center;
	overflow: hidden;
    border-radius: 7px;
}

.single-page {
	button.dark {
		border-color: #ff4931;
		background: #ff4931;
	}
	.move button.dark {
		border-color: #e0b722;
		background: #e0b722;
	}
	.splits p {
		font-size: 18px;
	}
	button {
		&:active {
			box-shadow: none;
		}
		&:focus {
			outline: 0;
		}
	}
	.wrapper {
		&.wrapper2 {
			top: -37%;
			left: 4%;
			background: $white;
			width: 100%;
			transition: all .5s;
			color: $white;
			overflow: hidden;
			border-radius: 7px;
		}
		background: $white;
		box-shadow: 0 10px 40px 0 rgba(62,57,107,0.1),0 2px 9px 0 rgba(62,57,107,0.1);
		transition: all .5s;
		color: #838ab6;
		overflow: hidden;
		border-radius: 7px;
		>form {
			width: 100%;
			transition: all .5s;
			background: $white;
			width: 100%;
		}
		.card-body {
			padding: 30px;
		}
		>form:focus {
			outline: 0;
		}
		#login {
			visibility: visible;
		}
		&.move {
			#register {
				transform: translateY(-80%) translateX(0);
				visibility: visible;
			}
			#login {
				transform: translateX(-100%);
				visibility: hidden;
			}
		}
		>form>div {
			position: relative;
			margin-bottom: 15px;
		}
		label {
			color: $color;
			float:left;
			font-weight:600;
		}
		h3,
		h4 {
			margin-bottom: 25px;
			font-size: 22px;
			color: $color;
		}
		input {
			height: 40px;
			padding: 5px 15px;
			width: 100%;
			border: solid 1px $border;
			border-radius:5px;
			&:focus {
				outline: 0;
				border-color: $border;
			}
		}
	}
	>.wrapper3 {
		width: 100%;
		padding-top: 8rem;
	}
	>.wrapper.move {
		left: 45%;
		input:focus {
			border-color: #e0b722;
		}
	}
	width: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
	border-radius: 7px;
	&.single-pageimage {
		min-height: 387px;
		&::before {
			content: '';
			background: $white-5;
		}
	}
	>.log-wrapper {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		color: $white;
	}
}

@media (min-width: 1024px) {
	.single-pageimage .log-wrapper {
		margin-top: 100px;
	}
}

.page-h,
 ::-ms-backdrop,
.imagecheck-figure {
	height: 100%;
}

.position-absolute,
 ::-ms-backdrop {
	position: absolute !important;
}

.feature .icons {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	line-height: 3em;
	vertical-align: middle;
	border-radius: 50%;
	border: 1px solid $white-1;
}

.location-gps {
	cursor: pointer;
	height: 20px;
	line-height: 33px;
	position: absolute;
	right: 12px;
	text-align: right;
	top: 18.5px;
	background: $white;
	width: 15px;
}

.loader-img .floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}

@keyframes floating {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.activity {
	position: relative;
	border-left: 1px solid #eaf2f9;
	margin-left: 16px;
	.img-activity {
		width: 42px;
		height: 42px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		left: -18px;
		-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}
	.item-activity {
		margin-left: 40px;
		margin-bottom: 19px;
	}
}

.bg-card-light {
	background: $white;
	text-align: center;
	transition: .4s;
	transform: scale(1);
	width: 100%;
	border-radius: 7px;
}

.status-border {
	border: 1px solid $border !important;
	&:hover {
		box-shadow: 0 5px 12px rgba(126, 142, 177, 0.2);
	}
}

.horizontal-main-1 {
	background: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.fa-1 {
	font-size: 1.5em;
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}

body .modal-open {
	padding-right: 0 !important;
	overflow: hidden;
}

.info .counter-icon {
	border: 1px solid rgba(255, 255, 255, 0.03) !important;
	background: rgba(251, 246, 246, 0.17);
}


.blog-list .item7-card-img {
	height: 197px !important;
}

.blog-list-1 {
	height: 196px;
}

@media screen and (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100% !important;
	}
	.item-card2 p.leading-tight,
	.item-card9 p.leading-tight {
		-webkit-line-clamp: 1 !important;
		-moz-line-clamp: 1 !important;
	}
}

.testimonia .owl-controls,
.testimonia1 .owl-controls {
	margin-bottom: 20px;
	margin-top: 0;
}

.banner-top .header-main {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1024;
}


/* background animations*/

.circles {
	position: initial;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	li {
		position: absolute;
		display: block;
		list-style: none;
		width: 20px;
		height: 20px;
		border: 0px solid $white-3;
		animation: animate 5s linear infinite;
		bottom: -150px;
		border-radius:50% !important;
		background:$white-1;
		&:nth-child(1) {
			left: 25%;
			width: 40px;
			height: 40px;
			animation-delay: 0s;
		}
		&:nth-child(2) {
			left: 10%;
			width: 20px;
			height: 20px;
			animation-delay: 2s;
			animation-duration: 12s;
		}
		&:nth-child(3) {
			left: 70%;
			width: 20px;
			height: 20px;
			animation-delay: 4s;
		}
		&:nth-child(4) {
			left: 40%;
			width: 35px;
			height: 35px;
			animation-delay: 0s;
			animation-duration: 18s;
		}
		&:nth-child(5) {
			left: 65%;
			width: 20px;
			height: 20px;
			animation-delay: 0s;
		}
		&:nth-child(6) {
			left: 75%;
			width: 30px;
			height: 30px;
			animation-delay: 3s;
		}
		&:nth-child(7) {
			left: 35%;
			width: 35px;
			height: 35px;
			animation-delay: 7s;
		}
		&:nth-child(8) {
			left: 50%;
			width: 25px;
			height: 25px;
			animation-delay: 15s;
			animation-duration: 45s;
		}
		&:nth-child(9) {
			left: 20%;
			width: 15px;
			height: 15px;
			animation-delay: 2s;
			animation-duration: 35s;
		}
		&:nth-child(10) {
			left: 85%;
			width: 35px;
			height: 35px;
			animation-delay: 0s;
			animation-duration: 11s;
		}
	}
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}
	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 3px;
	}
}

.smllogo-white {
	display: none;
}

.video-body-img {
	width: 100px;
	height: 100px;
	line-height: 94px !important;
	background: $white-2;
	border-radius: 50%;
	margin: 0 auto;
	margin-bottom: 20px;
}

i.car {
	line-height: 18px;
}

.client-img {
	background: $white;
	border: 1px solid #eaeef9;
	padding: 20px;
	border-radius: 7px;
	background: $background;
	img {
		margin: 0 auto;
	}
}

@media (min-width: 991px) {
	.clients-img img {
		position: absolute;
		&:nth-child(1) {
			left: 15px;
			width: 60%;
			box-shadow: 0 0 0 0 rgba(8, 12, 35, 0.33), 0 4px 16px 0 rgba(4, 17, 31, 0.47);
		}
		&:nth-child(2) {
			width: 60%;
			top: 30px;
			right: 50px;
			box-shadow: 0 0 0 0 rgba(8, 12, 35, 0.33), 0 4px 16px 0 rgba(4, 17, 31, 0.47);
		}
		&:nth-child(3) {
			bottom: -6px;
			left: 60px;
			width: 60%;
			box-shadow: 0 0 0 0 rgba(8, 12, 35, 0.33), 0 4px 16px 0 rgba(4, 17, 31, 0.47);
		}
	}
}

@media (max-width: 991px) {
	.clients-img img {
		margin-top: 20px;
	}
}

.icon-service1 {
	display: inline-flex;
	width: 70px;
	height: 70px;
	text-align: center;
	border-radius: 7px;
	align-items: center;
	justify-content: center;
	svg{
		fill:$white;
		height: 40px;
		width: 40px;
	}
	i{
		line-height: 1;
	}
	&.icon-primary {
		&:hover {
			background-color:transparent;
		}
	}
}

.icon-primary {
	color: $white;
	
}


/*Detail-page*/

.details-tab-content {
	border-radius: 0 3px 0px 0px;
}

.details-tab {
	border-radius: 0 0px 3px 3px !important;
}

.product-slider .class-video:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 0;
	border-radius: 7px;
}

.service-widgets:hover {
	border: $white-1 !important;
	transform: translate(0px, -10px);
	transition: all .5s;
	color: $white !important;
	cursor: pointer;
	.text-muted,
	.text-primary,
	.text-secondary,
	.text-success,
	.text-warning {
		color: $white !important;
	}
	.bg-primary-transparent,
	.bg-secondary-transparent,
	.bg-success-transparent,
	.bg-warning-transparent {
		background: $black-1;
	}
}

.video-list-thumbs .class-icon {
	font-size: 4rem;
	opacity: .8;
	position: absolute;
	right: 0;
	left: 0;
	top: 42%;
	margin: 0 auto;
	text-align: center;
	transition: all .5s ease-in-out;
	cursor: pointer;
}

.sectiontitle-design {
	display: inline-block;
	position: relative;
	margin-bottom: 8px;
	.icons {
		width: 0.5rem;
		height: 0.5rem;
		display: inline-block;
		position: relative;
		transform: rotate(45deg);
	}
	&:before {
		position: absolute;
		content: "";
		width: 30px;
		height: 2px;
		background: #44577a;
		left: -30px;
		top: 10px;
	}
	&:after {
		position: absolute;
		content: "";
		width: 30px;
		height: 2px;
		background: #44577a;
		right: -31px;
		top: 10px;
	}
}


/*Banner*/

.bannner-owl-carousel1 {
	&.banner-height {
		height: auto;
	}
	.slider-img {
		height: 100%;
		width: 100%;
		position: absolute;
		background-size: cover;
	}
	.banner-title {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 0.2rem;
		font-family: 'Poppins', sans-serif;
	}
	.banner-subtitle,
	.banner-desc {
		font-weight: 300;
	}
	.owl-nav button {
		border: 1px solid transparent !important;
		background: $black-1 !important;
		border-radius: 3px !important;
	}
	&.owl-carousel:hover .owl-nav button {
		background: $black-3 !important;
	}
}

@media (min-width: 992px) {
	.bannner-owl-carousel1 .item {
		height: 600px;
	}
}

@media (min-width: 1367px) {
	.bannner-owl-carousel1 {
		.banner-text {
			position: absolute;
			top: 250px;
			z-index: 2;
			width: 100%;
			color: $white;
			text-align: center;
		}
		.banner-title {
			font-size: 3rem;
		}
		.banner-subtitle {
			font-size: 2.2rem;
		}
		.banner-desc {
			font-size: 1.2rem;
			line-height: 35px;
		}
	}
}

@media (max-width: 767px) {
	.bannner-owl-carousel1 {
		.banner-text {
			top: 250px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 210px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.item {
			height: 600px;
		}
		.banner-title {
			font-size: 2.2rem;
		}
		.banner-subtitle {
			font-size: 1.5rem;
		}
		.banner-desc {
			font-size: 0.87rem;
			line-height: 1.5;
		}
	}
	.banner-search .form-group {
		margin-bottom: 10px !important;
	}
	.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li a {
		margin: 0 0 4px 0 !important;
		border-radius: 7px !important;
	}
	.course-details {
		.wideget-user-tab.wideget-user-tab3  {
			margin-bottom: 2rem;
		}
	}
}

@media (max-width: 1367px) and (min-width: 768px) {
	.bannner-owl-carousel1 {
		.banner-text {
			top: 250px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 210px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.item {
			height: 500px;
		}
		.banner-title {
			font-size: 2.2rem;
		}
		.banner-subtitle {
			font-size: 1.8rem;
		}
		.banner-desc {
			font-size: 1rem;
			line-height: 1;
		}
	}
	.banner-search .form-group {
		margin-bottom: 10px !important;
	}
}

@media (min-width: 1367px) {
	.slider.slider-header img {
		width: 100%;
	}
}

@media (max-width: 1366px) and (min-width: 1024px) {
	.slider.slider-header img {
		height: 560px;
		width: 100%;
	}
}

.about-widget h2 {
	font-size: 40px;
}
.icon-success {
	background-color: rgba(33, 182, 50, 0.13);
	color: $success;
}
.icon-warning {
	background-color: rgba(249, 167, 63, 0.2);
	color: $yellow;
}

/*---- ie css ---*/

*::-ms-backdrop,
.custom-control-label {
	position: static;
}

*::-ms-backdrop,
.media-body,
.media-start,
.media-end {
	display: list-item;
	vertical-align: top;
}

*::-ms-backdrop,
.table-responsive .media-body,
.media-start,
.media-end {
	display: table-cell;
}

*::-ms-backdrop,
.breadcrumb-3 li,
*::-ms-backdrop,
.breadcrumb-4 li,
*::-ms-backdrop,
.breadcrumb-1 li,
*::-ms-backdrop,
.breadcrumb-2 li {
	display: inline-block;
	text-align: center;
}

*::-ms-backdrop,
.iteam-all-icon {
	height: 100%;
}

*::-ms-backdrop,
.construction-image:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	z-index: 1;
	left: 0;
	right: 0;
}

*::-ms-backdrop,
.page-h,
*::-ms-backdrop,
.item-card7-img {
	height: 100%;
}

*::-ms-backdrop,
.market-place .item-card7-img {
	height: 185px;
}

*::-ms-backdrop,
.testimonia img,
*::-ms-backdrop,
.item-card8-img,
*::-ms-backdrop,
.item-card9-img {
	height: 100%;
}

*::-ms-backdrop,
.gradient-icon {
	color: #6963ff;
	background: none;
}

*::-ms-backdrop,
.xzoom {
	height: 436px;
	width: 100%;
}

*::-ms-backdrop,
.product-item2 {
	height: 100%;
}

.imagecheck-image {
	width: 100%;
}

@media (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100%;
	}
}

@media (max-width: 767px) {
	 ::-ms-backdrop,
	.item-card9-img {
		height: inherit !important;
	}
	 ::-ms-backdrop,
	.item-card9-imgs {
		height: 100% !important;
	}
}

@media (min-width: 991px) {
	*::-ms-backdrop,
	.map-content-width .item-card9-imgs {
		height: 197px !important;
	}
	*::-ms-backdrop {
		height: 100% !important;
		width: auto !important;
	}
	.map-content-width {
		.item-card9-imgs img {
			width: auto !important;
		}
	}
}

@media (max-width: 992px) {
	*::-ms-backdrop,
	.horizontal-megamenu .megamenu-content .link-list {
		display: inline-table !important;
	}
	*::-ms-backdrop,
	.map-content-width .item-card9-imgs img {
		width: 100% !important;
	}
	*::-ms-backdrop,
	.map-img {
		height: 197px;
	}
}

*::-ms-backdrop,
.label {
	display: -ms-inline-flexbox;
}

*::-ms-backdrop,
.d-list .card {
	width: inherit !important;
}

*::-ms-backdrop,
.full-screen-link.icon.nav-link {
	display: none;
}

.content-z-index {
	z-index: 99;
}

.sticky-pin {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
}

.content ,.content1 {
   height:270px;
   max-height:270px;
   position:relative
}

.wideget-user-desc {
    text-align: center;
}
.shape{
    bottom: -1px;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    width: 100%;
	
	svg{
		-webkit-transform: scale(2);
		transform: scale(2);
		width: 100%;
		height: auto;
		-webkit-transform-origin: top center;
		transform-origin: top center;
	}
}
.shape2{
	top: 0px;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    width: 100%;
    transform: rotate(-180deg);
	z-index:9;
	svg{
		-webkit-transform: scale(2);
		transform: scale(2);
		width: 100%;
		height: auto;
		-webkit-transform-origin: top center;
		transform-origin: top center;
	}
}
.main-banner-png{
	    position: absolute;
    right: 0;
    bottom: -110px;
}
.item-card2-icons-over-lay{
	display:none;
	padding: 6px 10px;
    background: $white;
    border-radius: 25px 0 0 25px;
    position: absolute;
    display: flex;
    right: -100px;
    top: 20px;
	transition:all 0.4s;
	.item-card2-icons{
		position:relative;
		top:auto;
		right:auto;
	}
}
.card:hover .item-card2-icons-over-lay{
	display:inline-flex !important;
	right:-3px;
	transition:all 0.4s;
}
.iteam-all-icon{
	position:relative;
	i{
		text-align: center;
		width: 60px;
		height: 60px;
		background: rgba(105, 100, 247, 0.15);
		border-radius: 5px;
		position: relative;
		display: block;
		line-height: 50px;
   		 padding: 5px 7px;
	}
}
.iteam-all-icon i {
	font-size: 1.5rem;
}

.iteam-all-icon i {
	font-size: 2.5rem;
}
.video-img{
	position:relative;
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		display: block;
		left: -25px;
		border-radius: 10px;
		top: 25px;
	}
}
.video-btn {
    box-shadow: 0 0 0 $white-9;
    animation: pulse 2s infinite;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height: 88px;
    font-size: 30px;
    background: $white-3;
    border: 6px solid $white-05;
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
.student-img{
	img {
		position:absolute;
		bottom:-5rem;
		right:0;
	}
}
.testimonial-img{
	position:relative;
	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:100%;
		top:10px;
		left:-10px;
		border-radius:7px;
	}
	img{
		position:relative;
	}
}
.absolute-student{
	position:absolute;
	bottom:-5rem;
}
.student-feature-icons{
	display:inline-block;
	li{
		width:50%;
		float:left;
	}
	i{
		width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    background: rgba(255, 255, 255, 0.15);
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    border-radius: 7px;
	}
}
.footer-feature-icons{
	i{
		width: 30px;
		height: 30px;
		font-size: 16px;
		line-height: 30px;
		background:  rgba(105, 100, 247, 0.15);
		display: inline-block;
		margin: 0 auto;
		text-align: center;
		border-radius: 7px;
		margin-bottom: 4px;
	}
}
.bg-background-1 {
	.header-text {
	  position: relative;
	}

	.content-text {
	  position: relative;
	}
  
	.select2-lg {
	  .select2-container .select2-selection--single {
		height: 54px !important;
	  }
  
	  .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 54px !important;
		padding-left: 20px !important;
	  }
	}
}
.map-background-search {
	.select2-lg {
	    .select2-container .select2-selection--single {
			height: 54px !important;
	    }
	   .select2-container--default .select2-selection--single  {
			border-radius: 0;
			.select2-selection__rendered {
				line-height: 54px !important;
				padding-left: 20px !important;
			}
	 	}
	}
}

.owl-carousel.testimonial-owl-carousel-01 .owl-item {
	.testimonia-img img {
	  border-radius: 10px;
	  box-shadow: 0px 3px 30px 0px $black-3;
	  padding: 10px;
	  width: 50%;
	  display: inline;
	}
  
	.testimonia-img1 {
	  background: $background;
	  border-radius: 7px;
	  padding: 25px 30px;
	}
}
.recent-course{
	border: 1px solid rgba($white, 0.1);
    color: $white;
    display: inline-block;
    background: rgba($white, 0.05);
    width: 90px;
    height: 74px;
    text-align: center;
    border-radius: 7px;
	i{
		display: block;
		font-size: 24px;
		margin-top: 14px;
		margin-bottom: 3px;
	}
	&:hover{
		color: $white;
	}
}
.works-timeline{
	position:relative;
	text-align:center;
	&:before{
		content: '';
		position: absolute;
		width: 108%;
		height: 1px;
		z-index: -1;
		display: block;
		border-top: 1px dashed #d7d6f1;
		top: 50px;
		right:-50%;
	}
	.icon-service1{
		width:100px;
		height:100px;
		line-height:100px;
		border-radius:7px;
		margin-bottom:15px;
	}
	&.works-timeline-last{
		&:before{
			display:none;
		}
	}
}
.viewmore-btn-icon{
	width: 1.8rem;
    height: 1.8rem;
    line-height: 2rem;
    border-radius: 50%;
    background: $background;
    display: block;
    margin: 0 auto;
    text-align: center;
	&:hover{
		color:$white;
	}
	&.viewmore-btn-icon-lg{
		width: 2.5rem;
		height: 2.5rem;
		line-height: 2.8rem;
		font-size:20px;
	}
}
.icon-circle-style{
	width:30px;
	height:30px;
	line-height:30px !important;
	border-radius:7px;
	background:$background;
	display:inline-block;
	text-align:center;
}
.special-section i{
	width:80px;
	height:80px;
	line-height:80px;
	border-radius:50%;
	display:inline-block;
	font-size:30px;
	margin-bottom:20px;
	text-align:center;
}
.special-section .special-section-img{
	width:60px;
	height:60px;
	line-height:60px;
	border-radius:50%;
	display:inline-block;
	margin-bottom:20px;
	text-align:center;
	img{
		width:70%;
	}
}
.bottom-footer-shape{
	bottom: -2px;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    width: 100%;
	color: #282834 !important;
}
.social-button{
	padding: 9px 13px;
    background: #e9eaf7;
    border-radius: 7px;
    font-weight: 600;
	&:hover{
		color:$white;
	}
}
.absolute-banner-section{
	margin-top:-50px;
}
.about-2 {
	border-bottom-left-radius: 1rem !important;
	border-top-right-radius: 1rem !important;
	border-radius:0 ;
	&:hover{
		.icon-bg{
			border-radius: 100% !important;
			background:$white;
		}
		.servic-data {
			color: $white;
			p ,h4{
				color: $white;
			}
		}
	}
	&.bg-primary{
		border-radius:0;
	}
}
.feature-1 {
	&:before{
		content: '';
		position: absolute;
		width: 93%;
		height: 95%;
		top: -12px;
		left: 85px;
		border-radius: 7px;
		border-top-right-radius: 150px;
	}
	.feature {
		position:relative;
		background-color: $white;
		border-top-right-radius: 150px;
		top:30px;
	}
}
.features {
	.iteam-all-icon i {
		background: transparent;
		margin-bottom: 20px;
		svg{
			width:80px;
			height:80px;
		}
	}
}
.team-section {
	.social-icons {
		box-shadow: 0px 0px 6px 0px $border !important;
	}
	.team-section-1 {
		background-color: rgba(105, 100, 247, 0.15);
	}
}

.item2-gl-nav .select2-container--default .select2-selection--single .select2-selection__rendered {
    text-align: left;
}

.aboutlink a {
    position: absolute;
    left: 50%;
    width: 220px;
    height: 80px;
    line-height: 138px;
    bottom: 0;
    margin-left: -110px;
    overflow: hidden;
}
.aboutlink a i{
    margin-left: 105px;
    font-size: 18px;
    animation: sk-bounce 1s infinite ease-in-out;
}
.aboutlink {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    bottom: 0;
}
.aboutlink a:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 400px 58px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA3NiAzMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzYgMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTcuNywxMS40Yy0xLjQtMS40LTIuNy0yLjktNC4xLTQuNGMtMC4yLTAuMy0wLjUtMC41LTAuOC0wLjhjLTAuMi0wLjItMC4zLTAuMy0wLjUtMC41bDAsMA0KCUM0OC42LDIuMiw0My41LDAsMzgsMFMyNy40LDIuMiwyMy42LDUuN2wwLDBjLTAuMiwwLjItMC4zLDAuMy0wLjUsMC41Yy0wLjMsMC4zLTAuNSwwLjUtMC44LDAuOGMtMS40LDEuNS0yLjcsMy00LjEsNC40DQoJYy01LDUuMS0xMS43LDYuMS0xOC4zLDYuM1YzMWg5LjRoOC45aDM5LjRoNC45SDc2VjE3LjZDNjkuNCwxNy40LDYyLjcsMTYuNSw1Ny43LDExLjR6Ii8+DQo8L3N2Zz4NCg==);
    top: 25px;
    left: 0;
    z-index: -1;
}
@media (max-width: 991px) {
	.feature-1:before {
		display: none;
	}
	.feature-1 .feature {
		display: none;
	}
}
@media (min-width: 992px) and (max-width: 1279px) {
	.feature-1 .feature {
		top: 74px;
		height: 410px;
	}
	.feature-1:before {
		width: 92%;
		height: 80%;
		top: 47px;
		left: 51px;
	}
}
.Categorie-1{
	.card-header a {
		i {
			font-size: 18px;
			top: 28px;
			line-height: 40px;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			text-align: center;
			background-color:$background;
		}
	}
	.card-body{
		border-top:0  !important;
	}
}
.table-user.card-table tr td:last-child, .card-table tr th:last-child {
    padding-right: 0;
	padding-left:0;
}
.product-tags-1 {
	a {
		float: left;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 7px;
		font-size: 14px;

	}
	li {
		a:hover {
			border-radius: 7px;
		}
		display: flex;
	}
} 
#commentForm #rootwizard .navtab-wizard li a{
	border-radius: 7px !important;
	padding: 0.7rem 1.5rem;
	border-left: 0;
}
#commentForm #rootwizard #fourth .tabs-menu1 ul li a {
    padding: 10px 20px 11px 20px;
    border-radius: 0;
    margin: 0;
    text-align:center;
}
.profile-1 {
	.profie-img {
		p {
			font-size: 16px;
		}
	}
	.wideget-user-desc .wideget-user-img img {
		border-radius: 7px;
		border:5px solid $border
	}
	.wideget-user-icons a{
		background-color: rgb(239, 240, 255);
	}
	.wideget-user-tab {
		.tabs-menu1 {
			ul {
				li {
					.active{
						border-bottom:0 !important;
   						 border-radius: 7px;
						color:$white !important;
					}
					a {
						padding: 10px 20px 10px 20px;
						background-color: $white;
						margin: 0 5px;
					}
				}
			} 
		} 
	}
	.table > thead > tr > th {
		font-weight: 700;
		background: $background;
		font-size:13px;
	}
}
@media (min-width: 767px) {
	.profile-1 { 
		#tab-6 {
			.tab-content #tab-11 .item-card9-imgs img, .tab-content #tab-11 .item-card2-img img {
				height: 215px;
			}
			.tab-content #tab-12 h3 {
				font-size: 1.2rem  !important;
			}
		}
	}
}

.profile-2 {
	.pattern-3 {
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			display: block;
			z-index: 0;
			top: 0;
		}
		background: url(../images/patterns/4.png) center;
		overflow: hidden;
		border-radius: 7px 7px 0 0;
	}
	.item-user .item-user-icons a {
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		font-size: 15px;
	}
	.wideget-user-icons a{
		background-color: rgb(239, 240, 255);
	}
	.wideget-user-tab {
		.tabs-menu1 {
			ul {
				li {
					.active{
						border-bottom:0 !important;
   						border-radius: 7px;
						color:$white !important;
					}
					a {
						padding: 10px 20px 10px 20px;
						margin: 0 5px;
						border: 1px solid $border;
						border-radius: 7px 7px 0 0 !important;
					}
				}
			} 
		} 
	}
	.profie-img p {
		font-size: 16px;
	}
	.table > thead > tr > th {
		font-weight: 700;
		background: $background;
		font-size:13px;
	}
	.file-browser {
		.input-group-btn .btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.form-control.browse-file {
			background: transparent;
		}
	}
	#tab-8 {
		.wideget-user-icons a {
			display: inline-block;
			width: 2rem;
			height: 2rem;
			text-align: center;
			border-radius: 100px;
			line-height: 2rem;
			margin-top: .3rem;
			border: $border 1px solid;
			background: transparent;
			&:hover {
				color:$white  !important;
			}
		}
	}
}
@media (min-width: 767px) {
	.profile-2 { 
		#tab-6 {
			.tab-content #tab-11 .item-card9-imgs img, .tab-content #tab-11 .item-card2-img img {
				height: 215px;
			}
			.tab-content #tab-12 h3 {
				font-size: 1.2rem  !important;
			}
		}
	}
}

/*-- My Dashboard--*/
.my-dash {
  .side-menu .slide li a {
    position: relative;
  }

  .slide, li {
    list-style-type: none;
  }

  .side-menu li ul li a.active:before {
    display: none;
  }

  .side-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    font-size: 14px;
    transition: border-left-color 0.3s ease, background-color 0.3s ease;
    border-top: 1px solid #e8ebf3;
  }

  &.app-sidebar .mCSB_draggerContainer {
    right: -11px;
  }

  .side-menu__icon {
    flex: 0 0 auto;
    width: 25px;
    font-weight: 500 !important;
  }

  .side-menu__label {
    white-space: nowrap;
    flex: 1 1 auto;
  }

  .slide.is-expanded [data-toggle='slide'] {
    border-bottom: 0px solid #e8ebf3;
  }

  .side-menu li {
    a {
      border-top: 1px solid #e8ebf3;
    }

    ul li a {
      border-top: 0;
      border-bottom: 0;
    }
  }

  &.app-sidebar {
    .side-menu li a.active {
      background: none !important;
    }

    .slide-menu li.active > a {
      font-weight: 500;
    }
  }

  .slide-menu li ul {
    li {
      font-size: 12px !important;
      font-weight: 300;
      border-bottom: 0px solid #e8ebf3;
    }

    a {
      padding: 10px 10px 10px 65px;
    }
  }

  .side-menu .slide-menu li a {
    font-size: 13px;
  }

  &.slide ul li a {
    color: $color;
    font-weight: 400;
  }

  &.app-sidebar-footer {
    clear: both;
    display: block;
    color: $white;
    position: fixed;
    bottom: 0px;
    background: $white;
    width: 228px;
    z-index: 999;
    border-top: 1px solid #e8ebf3;
  }

  .app-sidebar-footer a {
    padding: 10px 0;
    text-align: center;
    width: 20%;
    color: $white;
    font-size: 15px;
    display: block;
    float: left;
    cursor: pointer;
    border-right: 1px solid #e8ebf3;
    border-bottom: 1px solid #e8ebf3;

    &:hover {
      background: rgba(255, 255, 255, 0.02);
    }
  }

  .slide ul ul a {
    font-size: 13px !important;
    padding: 0px 0px 0px 64px !important;
  }

  &.app-sidebar ul li a {
    color: $default-color;
    font-weight: 600;
  }

  .slide.is-expanded {
    .slide-menu {
      display: block;
      background: $white;
    }

    .angle {
      transform: rotate(90deg);
    }
  }

  .slide-menu, .child-sub-menu {
    display: none;
  }

  .sub-slide {
    &.is-expanded {
      .child-sub-menu {
        display: block;
        background: $white;
      }

      .sub-angle {
        transform: rotate(90deg);
      }
    }

    .sub-angle {
      transform-origin: center;
      transition: transform 0.3s ease;
      margin-right: 20px;
    }
  }

  .slide-item {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 45px;
    font-size: 13px;
    color: #3e4b5b;
    height: 33px;
    line-height: 40px;
  }

  &.app-sidebar .side-menu .slide-menu li:last-child {
    padding-bottom: 10px;
    border-bottom: 0;
  }

  .slide-item .icon {
    margin-right: 5px;
  }

  .angle {
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  .app-sidebar ul li a {
    color: $color;
    font-weight: 400;
  }

  .slide.is-expanded {
    .slide-menu {
      display: block;
    }

    .angle {
      transform: rotate(90deg);
    }
  }

  .slide-menu {
    display: none;
  }

  .child-sub-menu li a {
    border-top: 0px solid rgba(0, 0, 0, 0.06) !important;
  }
}

.my-dash-1 .wideget-user-icons a {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border-radius: 100px;
  line-height: 2rem;
  margin-top: .3rem;
  background-color: $background;
}
/*-- My Dashboard--*/
.course-details {
	.wideget-user-tab {
		&.wideget-user-tab3 {
			.tabs-menu1 {
				ul  {
					li {
						.active {
							border-radius: 7px 7px 0 0;
							background:$white !important;
							padding: 12px 20px 12px 20px;
						}
						a {
							border-radius: 7px 7px 0 0;
							color: $white;
						}
					}
				}
			} 
		}
	}
}
.item7-card-text .item-card-badge {
    text-align: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 37px;
    font-size: 17px;
    left: auto;
    padding: 0;
    right: 10px;
    bottom: 10px;
    top: auto;
}
.faqs {
	.panel-heading1 {
		border-radius: 7px;
	}
	.panel-title1 a {
		color: $white;
		border-radius: 7px 7px 0 0;
		font-size: 15px;
		font-weight: 400;
	
		&.collapsed {
		background: transparent;
		color: $color;
		}
	}
	.panel-default {
		border-radius: 7px;
	}
	.panel-heading1 a {
		&.collapsed:before {
		content: "\e930";
		color: $muted;
		right: 18px;
		top: 15px;
		font-size: 1rem;
		}

		&:before {
		content: "\e92d";
		right: 18px;
		top: 15px;
		font-size: 1rem;
		}
	}	
	.panel-body {
		border-radius: 0 0 7px 7px;
	}										
}
.pricing-3{
	.pricing .btn {
		border-width: 1px !important;
		border-radius:7px  !important;
		&:hover {
			color: $white !important;
		}
		&.btn-secondary {
			color: $white !important;
		}
	}

} 
.bg-primary .pricing.pricing1 i{
	color:$border;
}
.pricing.pricing1 {
	font-family: 'Roboto', sans-serif;
}
.single-page .wrapper textarea {
    height: 100%;
    padding: 5px 15px;
    width: 100%;
    border: solid 1px $border;
    border-radius: 5px;
}
.single-page.contact .wrapper.wrapper2 {
    top: 0%;
    left: 0%;
}
.contacts .support-service {
	border-radius: 7px;
	i {
		float: none;
		margin-bottom: 15px;
	}
	p {
		color: rgba(255 ,255 ,255 , 0.9);
	}
	h5 {
		color:$white;
	}
}
.latest-timeline-2 {
	td{
		.activity1 {
			line-height: 3;
		}
	}
	.activity1 {
		width: 50px;
		height: 50px;
		position: relative;
		padding: 3px;
		margin-right: 20px;
		border-radius: 7px;
		margin-top: 0;
		text-align: center;
		line-height: 2;
		i{
			svg {
				fill:$white;
				width: 30px;
				height: 30px;
			}
		}
	}
}

.task-1{
	width: 60px;
	height: 60px;
	position: relative;
	color: $white;
	padding: 7px;
	margin-right: 15px;
	border-radius: 7px;
    text-align: center;
    line-height: 4.5;
	i{
		font-size:35px;
	}
}
.twitter-icon {
	.task-1{
		background: $info;
	}
	color:$color;
    margin-bottom: 1rem;
    border-radius: 7px;
    border: 1px solid $border;
	box-shadow: none !important;
	.social1-title {
		color:$info ;
	}
	&:hover {
		color:$white;
		transition: .3s  cubic-bezier(0,0,0);
		.task-1{
			background: $white;
			color: $info;
		}
		background-color: $info;
		.social1-title ,.social-content {
			color:$white ;
		}
	}
}


.Google-icon {
	color:$color;
    margin-bottom: 1rem;
    border-radius: 7px;
    border: 1px solid $border; 
	box-shadow: none !important;
	.task-1{
		background: #dd4b39;
	}
	.social1-title {
		color:#dd4b39 ;
	}
	&:hover {
		color:$white;
		transition: .3s  cubic-bezier(0,0,0);
		.task-1{
			background: $white;
			color: #dd4b39;
		}
		background-color: #dd4b39;
		.social1-title ,.social-content {
			color:$white ;
		}
	}
}

.facebook-icon {
	color:$color;
    margin-bottom: 1rem;
    border-radius: 7px;
    border: 1px solid $border;    
	box-shadow: none !important;
	.task-1{
		background: #3b5998;
	}
	.social1-title {
		color:#3b5998;
	}
	&:hover {
		color:$white;
		transition: .3s  cubic-bezier(0,0,0);
		.task-1{
			background: $white;
			color: #3b5998;
		}
		background-color: #3b5998;
		.social1-title ,.social-content {
			color:$white ;
		}
	}
}


.pinterest-icon {
	color:$color;
    margin-bottom: 1rem;
    border-radius: 7px;
    border: 1px solid $border;    
	box-shadow: none !important;
	.task-1{
		background: #bd081c;
	}
	.social1-title {
		color:#bd081c;
	}
	&:hover {
		color:$white;
		transition: .3s  cubic-bezier(0,0,0);
		.task-1{
			background: $white;
			color: #bd081c;
		}
		background-color: #bd081c;
		.social1-title ,.social-content {
			color:$white ;
		}
	}
}

.linkedin-icon {
	color:$color;
    margin-bottom: 1rem;
    border-radius: 7px;
    border: 1px solid $border;    
	box-shadow: none !important;
	.task-1{
		background: #0077b5;
	}
	.social1-title {
		color:#0077b5;
	}
	&:hover {
		color:$white;
		transition: .3s  cubic-bezier(0,0,0);
		.task-1{
			background: $white;
			color: #0077b5;
		}
		background-color: #0077b5;
		.social1-title ,.social-content {
			color:$white ;
		}
	}
}

.nav-tabs .nav-link:hover:not(.disabled) i, .nav-tabs .nav-link.active i{
    color: $white;
}
.top-bar-start .dropdown-menu.dropdown-menu-start.show {
    margin: 13px 0 0 0 !important;
}
.header-text1 .select2-container--default .select2-selection--single{
	border-radius: 0;
}
.users-list .select2-container{
	width: 20% !important;
}
@media screen and (max-width:1279px){
	.main-banner-png {
		position: relative;
		right: 0;
	    bottom: 0px; 
		margin: 0 auto;
		display: block;
	}
	.banner-1 .header-text {
		.search-background.bg-transparent {
			.form.g-0 {
				justify-content: center;
			}
		}
		.text-body {
			text-align: center;
		}
	}
	
	.top-bar .top-bar-start .contact {
		display: none;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1279px) { 
	.banner-1 .sptb-2.sptb-tab {
		padding-bottom:3rem;
	}
	.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 5px 10px !important;
	}
}
@media screen and (max-width:991px) {
	.sticky-wrapper.is-sticky .horizontal-main .horizontalMenu-list {
		 padding-top: 0!important; 
	}
	.student-feature-icons li {
		width: 100%;
	}
	.student-img  {
		display: none;
	}
	.testimonial-owl-carousel .owl-nav .owl-prev, .testimonial-owl-carousel .owl-nav .owl-next {
		top: 50% !important; 
	}
	.footer-main.footer-main1 {
		padding-bottom: 0;
	}
	.footer-main.bg-dark .payments li a {
		line-height: 3;
	}
	.footer-main.bg-dark .payments {
		margin:0 !important;
	}
	.testimonial-img:before {
		display: none;
	}
	.item .item7-card-desc{
		display: block !important;
	}
	.banner-1 .header-text {
		.text-body {
			text-align: center;
		}
	}
	.absolute-student {
		display: none;
	}
	.typewrite-text .form-control {
		border-radius: 7px !important;
	}
	.banner1 .slide-header-text {
		top: -14% !important;
	}
	.works-timeline:before {
		display: none;
	}
	.works-timeline{
		margin:0 0 25px 0;
	}
	.slider-images {
		.search-background h1{
			font-size:35px !important; 
		}
	}
	
	.card-blog-overlay2 {
		.search-background .form-group {
			border-radius: 7px;
		}
	}
	
	.top-bar-end{
		.custom {
			li span {
				display: none;
			}
		}
	}
	.top-bar .top-bar-start .socials li {
		margin:0 !important;
	}
	.icon-card-1 {
		display: inline-grid !important;
	}
	.item-list {
		.item-card9-desc a {
			font-size: 16px;
		}
		#tab-11 .item-card2-desc ul li {
			width: 100%;
		}
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	
	.item-list1 {
		.item-card9-desc a {
			font-size: 16px;
		}
		#tab-11 .item-card2-desc ul li {
			width: 100%;
		}
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	
	.item-list2 {
		.item-card9-desc a {
			font-size: 16px;
		}
		#tab-11 .item-card2-desc ul li {
			width: 100%;
		}
		#tab-11 .item-card2-desc ul li:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}
	}
	
	.map-content-width .item-card2-desc ul li:nth-last-child(2) {
		margin-bottom: 0.5rem;
	}

	.bg-background-1 .header-style.horizontal-main .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu, 
	.bg-background-1 .header-style.horizontal-main .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		 top: 0px !important; 
	}
	.header-text1 {
		.search-background {
			border-radius: 7px !important;
			.form-group {
				border-radius: 7px !important;
			}
		}
	}
	.user-tabel .card-table tr td:last-child {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
	
	#commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li a {
		border-right: 0 !important;
	}
	#commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li {
		width:100% !important;
		border-bottom: 1px solid $border;
	}
	#commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li:last-child {
		border-bottom: 0px solid $border;
	}
	.profile-1 .wideget-user-tab .tabs-menu1 ul li {
		width: 100%;
		margin: 5px 6px 0 0;
		a {
			padding: 12px !important;
			text-align: center;
		}
	}
	.profile-2 .wideget-user-tab .tabs-menu1 ul li a {
		border-radius: 7px !important;
	}
		
	.error-page{
		img{
			height: auto !important;
		}
	}
	.absolute-banner-section{
		margin-top:0 !important;
		padding-top:3rem;
	}
}
#commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li {
   margin:0;
}
@media screen and (max-width:767px) { 
	#myCarousel.testimonial-owl-carousel p{
		text-align:center;
	}
	#myCarousel.testimonial-owl-carousel .d-md-flex{
		text-align:center;
	}
	
	.banner-1 .header-text {
		.text-body {
			margin-bottom:1rem !important;
		}
		p {
			margin-bottom: 0;
		}
	}
	#myCarousel1 {
		.item-card7-desc {
			.pt-2 {
				display: grid;
			}
			.item-card7-text h4{
				white-space: nowrap;
			}
		}
	}
	.header-main .top-bar-light .top-bar-end .custom li:last-child a {
		border-right:1px solid $white-1;
	}
	.items-gallery .items-blog-tab-heading .items-blog-tab-menu li a {
		margin: 0 5px;
	}
	.page-options {
		margin-top:10px;
	}
	#commentForm #rootwizard .navtab-wizard li a {
		margin-top:5px;
	}
	.payments .tabs-menu1 ul li {
		width: 100%;
		a{
			margin:3px 0 !important;
		}
	}
	
	.my-orders .tabs-menu1 ul li {
		width: 100%;
		
	}
	.course-details .profile-pic img {
		margin-bottom:12px;
	}
	
	.course-details #tab-3 .card-body {
		padding-top:0 !important;
	}
}
@media screen and (max-width:411px) and (min-width:361px) { 
	.main-banner-png {
		height: 315px;
	}
}
@media screen and (max-width:375px) {
	.shape {
		bottom: -4px;
	}
}
@media (max-width: 480px) {
	.banner1 {
		.slider {
			.header-text {
				top: 30% !important;
			}
			img {
				height: 735px;
			}
		}
		&.slider-images .header-text {
			top: 15% !important;
		}
	}
	.sptb-2 {
		padding-top: 5rem !important;
		padding-bottom: 3rem !important;
	}
	.sptb {
	    padding-top: 2.5rem;
	    padding-bottom: 2.5rem;
	}
	.sptb-tab.sptb-2.pt-10 {
		padding-top: 6.5rem !important;
	}
	.tab-content.item-list .tab-pane .card-body a {
		display: inline-block;
		width:auto;
	}
	.tab-content.item-list .tab-pane .item-card9-icons2 .item-card9-icons1 {
		width:2rem;
	}
	.item-list {
		.item-card9-icons2 {
			position: relative;
			right: 0;
		}
	}
	.tab-content.item-list1 .tab-pane .card-body a {
		display: inline-block;
		width:auto;
	}
	.tab-content.item-list1 .tab-pane .item-card9-icons2 .item-card9-icons1 {
		width:2rem;
	}
	.item-list1 {
		.item-card9-icons2 {
			position: relative;
			right: 0;
		}
	}
	
	.tab-content.item-list2 .tab-pane .card-body a {
		display: inline-block;
		width:auto;
	}
	.tab-content.item-list2 .tab-pane .item-card9-icons2 .item-card9-icons1 {
		width:2rem;
	}
	.item-list2 {
		.item-card9-icons2 {
			position: relative;
			right: 0;
		}
	}
	.profile-2 .wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li {
		margin: 3px 0 !important;
	}
	
	.profile-2 .wideget-user-tab.wideget-user-tab3  {
		margin-bottom: 2rem;
	}
	.course-details .profile-pic h6 a{
		margin:3px 0;
	}
	.course-details #tab-5 .media .media-body h4 {
		display: grid;
	}
}
@media (max-width: 568px) {
	.banner1 ,.section-first ,.bg-background-dark ,.bg-background-1{
		.header-main .top-bar .top-bar-start {
		  justify-content: center;
		  border-bottom: 1px solid $white-1;
		  width: 100%;
		}
		.top-bar .col-12 {
		  padding: 0;
		}
	  
		.header-main .top-bar .top-bar-end .custom {
		  text-align: center;
		  float: none;
		  justify-content: center;
		}
	  
		.slide-header-text {
		  top: -3% !important;
		}
	}
	.header-main .top-bar.top-bar-light .top-bar-start {
		justify-content: center;
		border-bottom: 1px solid #e9eaf9 ;
		width: 100%;
	}
	.top-bar.top-bar-light .col-12 {
		padding: 0;
	}
	
	.header-main .top-bar.top-bar-light .top-bar-end .custom {
		text-align: center;
		float: none;
		justify-content: center;
	}
	.header-main .top-bar-end .custom li a {
		line-height: 20px !important;
		i {
			line-height: 16px;
		}
	}
}
.bg-background-dark .top-bar{
    border-bottom: 1px solid $black-2;
}
.icon-card-1 {
    display: flex;
	li {
		float: left;
		font-size: 13px;
		margin-bottom: .5rem;
	}
}
.item-list1 #tab-11 .item-card2-desc ul li {
    width: auto; 
}

.item-list2 #tab-11 .item-card2-desc ul li {
    width: auto; 
}
.profile-2 .tabs-menu1 ul li {
	margin: 0;
}

#commentForm {
	.nav-tabs .nav-link {
	  background: $background;
	}
}

.bg-background-1, .banner1{
	.sticky-wrapper.is-sticky{
		.horizontalMenu > .horizontalMenu-list > li > a {
			color:$default-color;
		}
	}
}

.bg-background3{
	.sticky-wrapper.is-sticky{
		.horizontalMenu > .horizontalMenu-list > li > a {
			color:$default-color;
		}
	}
}

@media (max-width: 991px) {
	.active {
	  .bg-background-1 .sticky-wrapper .horizontalMenu > .horizontalMenu-list > li > a.active, .bg-background3 .sticky-wrapper .horizontalMenu > .horizontalMenu-list > li > a.active, .banner1 .sticky-wrapper .horizontalMenu > .horizontalMenu-list > li > a.active {
	    color: $white;
	  }
	}
	body.active .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li {
	  &:hover > a {
	    background-color: $white;
	  }
	
	  > ul.sub-menu > li {
	    &:hover > a {
	      background-color: $white;
	    }
	
	    > ul.sub-menu > li:hover a {
	      color: $white;
	    }
	  }
	}
}

#commentForm {
	.navtab-wizard.nav-tabs .nav-link {
	  &.active, &:hover {
		color: $white;
	  }
	}
  }
  
.header-text {
	.breadcrumb-item+.breadcrumb-item {
	    padding-left: 0;
	}
}

@media (max-width: 991px) {
	.banner1 ,.section-first ,.bg-background-dark{
		.header-main .top-bar {
			border-bottom: 0 !important;
		}
	}
	.sptb-2 {
	    padding-top: 3rem;
		padding-bottom:3rem;
	}
	.bg-background-1 .horizontal-header {
		border-bottom: 0 !important;
	}
}

@media (max-width: 568px) {
	.recent-course {
		margin: 5px;
	}
	.slider-images .input-field {
		margin-top: 10.55rem !important;
	}
}

@media (min-width: 568px) {
	.recent-course{
		margin:0 2px;
	}
}

@media (min-width: 1025px) {
	.input-field {
		margin-top: 14.55rem !important;
	}
}
.profile-1 ,.profile-2{
	#tab-8 {
		.card .card {
			border-radius:7px;
		}
	}
}

.construction-image {
	.display-1 {
		font-size: 10.5rem;
		font-weight: 400;
		line-height: 1.1;
	}
}
.progress-bar-success {
	background-color: $success !important;
}
.push {
	.table td {
		vertical-align: middle;
	}
}
textarea.form-control {
    min-height: auto;
    height: auto;
}
.dataTables_length {
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 30px !important;
	}
}
.app-sidebar .side-menu li.is-expanded .sub-slide.is-expanded li a {
    color: $color;
}
.tab-pane{
	table {
		&.data-table-example{
			th{
				width: initial !important;
			}
		}
	}
} 
.testimonial-1 {
	.team-section .team-img img {
		max-width: 70px;
	}
	p {
		.fa{
			font-size:12px ;
			color:$muted;
			opacity: 0.5;
		}
	}
}
.customerpage {
	.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
		width: auto;
	}
}
.client-carousel  {
	.br-theme-fontawesome-stars .br-widget {
		height: 20px;
	}
}
.task-widget {
	.item-card .item-card-desc:before {
		background: transparent;
	}
}
.pull-end {
	float: right;
}
.pull-start {
	float:left;
}
.type--strikethrough {
	text-decoration: line-through;
	opacity:0.7;
}
.widget-spec-1 li {
    margin-bottom: .5rem;
    font-size: 15px;
	i{
		margin-right: 10px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.productdetail {
	background: $background;
}
.user-pro-list:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 80px;
    background-size: cover;
    background-position: center;
    background: linear-gradient(rgba(58, 58, 199, 0.8),rgba(58, 58, 199, 0.8)),url(../images/patterns/circle4.png);
}
.user-pro-list .avatar {
    border: 3px solid $white;
    top: 10px;
    margin-bottom: 10px;
}
.user-pro-list .avatar-status {
    right: 8px;
    bottom: 2px;
}
.user-details{
	.table > :not(caption) > * > * {
		border-bottom-width: 0px;
	}
}
.profile-share {
    width: 100%;
    padding: 10px;
    border-radius: 0 0 7px 7px;
    border: 1px solid $border;
    display: flex;
}
.profile-edit {
	textarea {
		border-radius: 7px 7px 0 0;
	}
} 
.bannerimg {
	.header-text {
		h1 {
			color:$white;
		 }
	}
}
.info  {
	.counter-status{
		h5,h2 {
			color:$white;
		}
	}
}
.bg-background-1 {
	.content-text ,.header-text1 ,.header-text {
		h1 {
			color:$white;
		}
	}
}
.bg-background3 ,.slider-images{
	.header-text {
		h1 {
			color:$white;
		}
	}
}
.bg-background-color {
	.section-title h2 {
		color:$white;
	}
}
.item-card-desc {
	.item-card-text {
		h4 ,h3 {
			color:$white;
		}
	}
}
.icon-card-1 {
	i {
		color: $color;
		opacity: 0.6;
	}
	span {
		color:$default-color;
	}
}
.item-card2  {
	.br-theme-fontawesome-stars .br-widget {
		height: 0;
	}
}
.blog-list {
	.item-card-badge i {
	    color: $white;
	}
}

.header-bg {
	background:  $secondary;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(111.7deg, rgba(146, 30, 152, 0.7) 19.9%, rgba(59, 25, 249, 0.7) 95%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(-225deg, rgba(23, 13, 177, 0.7) 35%, rgba(236, 50, 87, 0.7) 100%) !important;
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
	background: $secondary;
}

.heading-secondary {
	background-color: $secondary;
	color: $white;
	padding: 5px;
}

.feature-animation {
	&.feature {
		.icons {
			&.icon-2 {
				background-color: $transparent-secondary;
				border-color: $secondary;
			}
		}
	}
}

.icon-secondary {
	background-color: $transparent-secondary;
	color: $secondary;
}
.sweet-alert-1 {
	.table > :not(caption) > * > * {
		 border-bottom-width: 0; 
	}
}
.authors {
	.badge {
		padding: 0.6rem 0.8rem;
	}
}
.top-bar  {
	.top-bar-end {
		.custom {
			.dropdown-menu-arrow{
				&:before {
					top: -10px;
					border-right: 10px solid transparent;
					border-bottom: 10px solid  $white;
					border-left: 10px solid transparent;
				}
				&:after {
					top: -10px;
					border-right: 10px solid transparent;
					border-bottom: 10px solid $white;
					border-left: 10px solid transparent;
				}
			} 
		}
	}
	.top-bar-start {
		.contact {
			.dropdown-menu-arrow{
				&:before {
					top: -10px;
					border-right: 10px solid transparent;
					border-bottom: 10px solid  $white;
					border-left: 10px solid transparent;
				}
				&:after {
					top: -10px;
					border-right: 10px solid transparent;
					border-bottom: 10px solid $white;
					border-left: 10px solid transparent;
				}
			} 
		}
	}
}

.gallery-item {
	.card-body {
		strong {
			&.badge {
				padding: 0.35em 0.65em;
			}
		}
	}
	.gallery-card {
		.gallery-card-desc {
		  img {
			transform: scale(1);
			transition: .3s ease-in-out;
			width: 100%;
			height: 100%;
			border-radius: 7px;
		  }
	  
		  &:hover img {
			transform: scale(1.2);
		  }
		}
	  
		overflow: hidden;
		position: relative;
		border-radius: 7px;

		.gallery-card-desc:before {
		  content: '';
		  display: block;
		  position: absolute;
		  background: transparent;
		  width: 100%;
		  height: 100%;
		  right: 0;
		  top: 0;
		  z-index: 1;
		  border-radius: 7px;
		}
	  
		&:hover .gallery-card-desc:before {
		  background: rgba(105, 100, 247, 0.43) !important;
		}
	}
	  
	.gallery-card-img {
		overflow: hidden;
	}
	  
	.gallery-card-desc {
		position: relative;
	}
}
.manged-ad,.ads-tabs,.my-favadd ,.my-orders ,.payment-invoice{
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 19px;
	}
}
.my-orders ,.payment-invoice{
	.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 30px !important;
	}
} 
.profile-carousel{
	&.owl-carousel.owl-drag .owl-item {
		padding: 0px !important;
	}
}
.course-details,.profile-1 {
	.tabs-menu1 ul li {
		width: 19%;
		text-align: center;
	}
}
.top-bar {
	.top-bar-end{
		.custom{
			.dropdown-menu.show {
				border: 0;
				box-shadow: none;
			}
		}
	}
}
.classes-carousel-1{
	.item-card {
		.item-card-desc {
			.item-card-text {
				transform: translate(-50%, -50%);
				top: 50%;
				left: 48%;
				bottom: auto;
			}
		}
	}
}
.video-icon {
	i{
		background-color: $white;
		color: $primary;
		transition: .2s ease;
		display: inline-flex;
		justify-content: center;
		vertical-align: middle;
		width: 3.5rem;
		height: 3.5rem;
		line-height: 3.5rem;
		border-radius: 100%;
		padding-left: 3px;
	}
	font-size:inherit !important;
	opacity:1 !important;
	&:hover {
		i {
			background-color: $primary;
			color: $white;
		}
	}
} 
@media screen and (max-width:767px) {
	.course-details .wideget-user-tab.wideget-user-tab3 {
		.tabs-menu1 ul li {
			width: 100%;
			a{
				padding: 15px !important;
			}
		}
		margin-bottom: 2rem;
	}
}
@media (max-width: 991px) {
	.bg-background-1.bg-background-dark {
		.header-text{
			h1{
				font-size: 2rem !important;
			}
		}
		.horizontal-header {
			border-bottom: 1px solid $white-09 !important;
	   }
	}
}

@media (max-width: 480px) {
	.horizontalMenucontainer{
		.page-header{
			.breadcrumb{
				margin-top:10px;
			}
		}
	}
}
@media (max-width: 991px){
	.active .banner-1.bg-background3 .sticky-wrapper .horizontalMenu > .horizontalMenu-list > li > a.active {
		background-color: transparent;
		color:$primary;
	} 
}
input {
    -webkit-appearance: none;
}

textarea {
    -webkit-appearance: none;
}
.ads-tabs{
	.tabs-menus{
		ul{
			li{
				.active {
					.badge{
						background: $background;
						color:$primary;
					}
				}
			}
		}
	}
}
a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}
#commentForm-1 {
	#commentForm {
		#rootwizard {
			.wizard {
				border: 0;
			}
		}
	}
}
@media (max-width: 991px)  {
	.section-first.bg-background-1 .horizontal-header {
		border-bottom: 1px solid $white-1 !important;
   }
   .header-banner{
	padding-top: 11rem !important;
   }
   .bg-background-1  {
		.top-bar  {
			border-bottom: 0px solid $white-1 !important;
		}
		&.banner-1 {
			.horizontal-header{
				border-bottom: 1px solid $white-1 !important;
			}
		}
   }
}
@media screen and (max-device-width: 991px) and (orientation: portrait) {
	body {
		.horizontalMenucontainer{
			position: -webkit-sticky;
			.header-absolute{
				position: absolute;
				width:100%;
			}
		}
		&.modal-open{
			.horizontalMenucontainer{
				position: relative;
			}
		}
	}
}

@media screen and (max-device-width: 991px) and (orientation: landscape) {
	body {
		.horizontalMenucontainer{
			position: -webkit-sticky;
			.header-absolute{
				position: absolute;
				width:100%;
			}
		}
		&.modal-open{
			.horizontalMenucontainer{
				position: relative;
			}
		}
	}
}
.top-bar.top-bar-light .top-bar-end .custom {
	.dropdown-menu.show {
	  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
	}
  
	.dropdown-menu-arrow {
	  &:before, &:after {
		border-bottom: 10px solid #6964f7;
	  }
	}
}

@media screen and (max-width:444px){
	.review-media {
		display: block !important;
		width:100%;
		overflow: intial;
		.media-body {
			display: grid;
		}
	}
}