.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
}
.demo-accordion {
	.acc_section {
		&.acc_active{
			.acc_head h3 {
				color:$white;
			}
		}
	}
}
#accordion1 {
	.panel-default>.panel-heading+.panel-collapse>.panel-body {
		border-top: 0;
	}
	.panel-default>.panel-heading {
		background-color: transparent; 
		border: 1px solid $border;
	}
}