.input-lg {
	height: 46px !important;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 3px;
}
.input-xl {
	height: 54px !important;
	padding: 10px 16px;
	font-size: 18px !important;
	line-height: 1.3333333;
	border-radius: 3px;
}
.input-sm {
	height: 30px !important;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: .9375rem;
	line-height: 1.6;
	color:$color;
	height: 39px;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $border;
	border-radius: 7px;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	&.border-white {
		border: 1px solid rgba(167, 180, 201, 0.7) !important;
	}
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	&:focus {
		color: $color;
		background-color: $white;
		border-color: $border;
		outline: 0;
		box-shadow: none;
	}
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder {
		color: $color;
	}
	&::placeholder {
		color: $color;
	}
	&:disabled,
	&[readonly] {
		background-color: $border;
		opacity: 1;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}

select.form-control {
	&:not([size]):not([multiple]) {
		height: 2.425rem;
	}
	&:focus::-ms-value {
		color: $color;
		background-color: $white;
	}
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}

.col-form-label {
	padding-top: calc(.375rem + 1px);
	padding-bottom: calc(.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.6;
}

.col-form-label-lg {
	padding-top: calc(.5rem + 1px);
	padding-bottom: calc(.5rem + 1px);
	font-size: 1.125rem;
	line-height: 1.44444444;
}

.col-form-label-sm {
	padding-top: calc(.25rem + 1px);
	padding-bottom: calc(.25rem + 1px);
	font-size: .875rem;
	line-height: 1.14285714;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin-bottom: 0;
	line-height: 1.6;
	color: $color;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
	&.form-control-lg,
	&.form-control-sm {
		padding-right: 0;
		padding-left: 0;
	}
}

.input-group-lg> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-text>.form-control-plaintext {
		&.btn,
		&.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.input-group-sm> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-text>.form-control-plaintext {
		&.btn,
		&.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.form-control-sm {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.14285714;
	border-radius: 7px;
}

.input-group-sm> {
	.form-control {
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.14285714;
		border-radius: 3px;
	}
	.input-group-text> {
		.btn,
		.input-group-text {
			padding: .25rem .5rem;
			font-size: .875rem;
			line-height: 1.14285714;
			border-radius: 3px;
		}
	}
}

.form-control-lg {
	padding: .5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.44444444;
	border-radius: 3px;
}

.input-group-lg> {
	.form-control {
		padding: .5rem 1rem;
		font-size: 1.125rem;
		line-height: 1.44444444;
		border-radius: 3px;
	}
	.input-group-text> {
		.btn,
		.input-group-text {
			padding: .5rem 1rem;
			font-size: 1.125rem;
			line-height: 1.44444444;
			border-radius: 3px;
		}
	}
}

.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: .25rem;
}

.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	> {
		.col,
		[class*=col-] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}

.form-checkbox {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-checkbox-input {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem;
	&:disabled~.form-checkbox-label {
		color: $color;
	}
}

.form-checkbox-label {
	margin-bottom: 0;
}

.form-checkbox-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: .75rem;
	.form-checkbox-input {
		position: static;
		margin-top: 0;
		margin-right: .3125rem;
		margin-left: 0;
	}
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 87.5%;
	color: $success;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: $white;
	background-color: rgba(94, 186, 0, 0.8);
	border-radius: .2rem;
}

.form-select.is-valid,
.form-control.is-valid {
	border-color: $success;
}

.was-validated {
	.form-select:valid,
	.form-control:valid {
		border-color: $success;
	}
}

.form-select.is-valid:focus,
.form-control.is-valid:focus {
	border-color: $success;
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.was-validated {
	.form-select:valid:focus,
	.form-control:valid:focus {
		border-color: $success;
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
	}
}

.form-select.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-control.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated {
	.form-select:valid~ {
		.valid-feedback,
		.valid-tooltip {
			display: block;
		}
	}
	.form-control:valid~ {
		.valid-feedback,
		.valid-tooltip {
			display: block;
		}
	}
}

.form-checkbox-input.is-valid~.form-checkbox-label,
.was-validated .form-checkbox-input:valid~.form-checkbox-label {
	color: $success;
}

.form-checkbox-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .form-checkbox-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-file-input.is-valid~.form-file-label,
.was-validated .form-file-input:valid~.form-file-label {
	border-color: $success;
}

.form-file-input.is-valid~.form-file-label::before,
.was-validated .form-file-input:valid~.form-file-label::before {
	border-color: inherit;
}

.form-file-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .form-file-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-file-input.is-valid:focus~.form-file-label,
.was-validated .form-file-input:valid:focus~.form-file-label {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 87.5%;
	color: $danger;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: $white;
	background-color: rgba(205, 32, 31, 0.8);
	border-radius: .2rem;
}

.form-select.is-invalid,
.form-control.is-invalid {
	border-color: $danger;
}

.was-validated {
	.form-select:invalid,
	.form-control:invalid {
		border-color: $danger;
	}
}

.form-select.is-invalid:focus,
.form-control.is-invalid:focus {
	border-color: $danger;
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated {
	.form-select:invalid:focus,
	.form-control:invalid:focus {
		border-color: $danger;
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
	}
}

.form-select.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-control.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated {
	.form-select:invalid~ {
		.invalid-feedback,
		.invalid-tooltip {
			display: block;
		}
	}
	.form-control:invalid~ {
		.invalid-feedback,
		.invalid-tooltip {
			display: block;
		}
	}
}

.form-checkbox-input.is-invalid~.form-checkbox-label,
.was-validated .form-checkbox-input:invalid~.form-checkbox-label {
	color: $danger;
}

.form-checkbox-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .form-checkbox-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-file-input.is-invalid~.form-file-label,
.was-validated .form-file-input:invalid~.form-file-label {
	border-color: $danger;
}

.form-file-input.is-invalid~.form-file-label::before,
.was-validated .form-file-input:invalid~.form-file-label::before {
	border-color: inherit;
}

.form-file-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .form-file-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-file-input.is-invalid:focus~.form-file-label,
.was-validated .form-file-input:invalid:focus~.form-file-label {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center;
	.form-checkbox {
		width: 100%;
	}
}

@media (min-width: 576px) {
	.form-inline {
		label {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			margin-bottom: 0;
		}
		.form-group {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
			-ms-flex-flow: row wrap;
			flex-flow: row wrap;
			-ms-flex-align: center;
			align-items: center;
			margin-bottom: 0;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-plaintext {
			display: inline-block;
		}
		.form-select,
		.input-group {
			width: auto;
		}
		.form-checkbox {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}
		.form-checkbox-input {
			position: relative;
			margin-top: 0;
			margin-right: .25rem;
			margin-left: 0;
		}
		.custom-control {
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		.custom-control-label {
			margin-bottom: 0;
		}
	}
}

.form-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: .5rem 1.75rem .5rem .75rem;
	line-height: 1.5;
	vertical-align: middle;
	background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 8px 10px;
	border: 1px solid $border;
	border-radius: 7px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5);
		&::-ms-value {
			color: $color;
			background-color: $white;
		}
	}
	&[multiple],
	&[size]:not([size="1"]) {
		height: auto;
		padding-right: .75rem;
		background-image: none;
	}
	&:disabled {
		color: $color;
		background-color: $border;
	}
	&::-ms-expand {
		opacity: 0;
	}
}

.form-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 75%;
}

.form-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 125%;
}

.form-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}

.form-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;
	&:lang(en)~.form-file-label::after {
		content: "Browse";
	}
}

.form-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 2.375rem;
	padding: .375rem .75rem;
	line-height: 1.8;
	color: $color;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 7px;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(2.375rem - 1px * 2);
		padding: .375rem .75rem;
		line-height: 1.5;
		color: $white;
		content: "Browse";
		border-radius: 0 7px 7px 0;
	}
}

.form-group {
	display: block;
}

.form-label {
	display: block;
	margin-bottom: .375rem;
	font-weight: 700;
	font-size: .975rem;
}

.form-label-small {
	float: right;
	font-weight: 400;
	font-size: 87.5%;
}

.form-footer {
	margin-top: 2rem;
}

.form-control.header-search {
	background: $white-1;
	border: 1px solid $border !important;
	color: $color;
	width: 100%;
	border-radius: 12em;
	&:focus,
	&:hover {
		border: 0;
		box-shadow: none;
	}
}

.input-icon {
	position: relative;
	.form-control {
		&:not(:last-child),
		&:not(:first-child) {
			padding-left: 2.5rem;
		}
	}
}

.input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	color: $color;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	&:last-child {
		left: auto;
		right: 0;
	}
}

.form-fieldset {
	background: $background;
	border: 1px solid $border;
	padding: 1rem;
	border-radius: 3px;
	margin-bottom: 1rem;
}

.form-required {
	color: $danger;
	&:before {
		content: ' ';
	}
}

.state-valid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>") no-repeat center right 0.5rem / 1rem;
}

.state-invalid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>") no-repeat center right 0.5rem / 1rem;
}

.form-help {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	text-align: center;
	line-height: 1rem;
	background: $border;
	border-radius: 50%;
	font-size: .75rem;
	transition: .3s background-color, .3s color;
	text-decoration: none;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover,
	&[aria-describedby] {
		color: $white;
	}
}

.form-group.has-feedback span {
	display: block !important;
}

.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}

.wizard-card {
	.moving-tab {
		margin-top: 5px;
	}
	&.form-group .form-control {
		background-image: linear-gradient(#c4c4c4, #c4c4c4);
	}
	.choice .icon {
		border: 4px solid $border !important;
	}
}

.wizard-container .wizard-navigation {
	background: $border;
}

.richText {
	.richText-toolbar {
		ul {
			li a {
				border-right: $border solid 1px;
			}
			background: $background;
			border-bottom: $border solid 1px;
		}
		border-bottom: $border solid 1px;
		background: $background;
		border-top: #e5f0ff solid 1px;
	}
	border: $border solid 1px;
	background-color: $background;
}

.customerpage .btn-icon {
	border: 1px solid rgba(96, 94, 126, 0.2);
}

.form .btn {
	position: relative;
	right: 0px;
}