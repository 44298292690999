.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0 !important;
	list-style: none;
	border-radius: 3px;
	left: 0;
	bottom: 0;
	padding: 0 !important;
	background-color: transparent !important;
}

.breadcrumb-item a {
	color: $white-8;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
	color: $color;
}

.breadcrumb-item+.breadcrumb-item {
	&::before {
		display: inline-block;
		padding-right: .5rem;
		padding-left: .5rem;
		color: $white-3;
		content: "/";
	}
	&:hover::before {
		text-decoration: underline;
		text-decoration: none;
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0;
	margin-bottom: 1rem;
	list-style: none;
	background-color:transparent;
	border-radius: .25rem;
}

.breadcrumb-item1 {
	a {
		color: $color;
		&:hover {
			text-decoration: none;
		}
	}
	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-right: .5rem;
			padding-left: .5rem;
			content: "\e933";
			font-family: "feather";
			opacity: 0.6;
			color: $muted;
			margin-top:1px;
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: $primary ;
	}
}

.breadcrumb-1,
.breadcrumb-2 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: right;
	}
	li {
		text-align: right;
		display: contents;
	}
}

.breadcrumb-3,
.breadcrumb-4 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
	li {
		display: contents;
		text-align: center;
	}
}

.breadcrumb-3 li,
 ::-ms-backdrop,
.breadcrumb-4 li,
 ::-ms-backdrop,
.breadcrumb-1 li,
 ::-ms-backdrop,
.breadcrumb-2 li,
 ::-ms-backdrop {
	display: inline-block;
	text-align: center;
}
.card-breadcrumb .page-header {
    padding: .75rem !important;
}

.breadcrumb2 {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0;
	margin-bottom: 1rem;
	list-style: none;
	background-color:transparent;
	border-radius: .25rem;
}

.breadcrumb-item2 {
	a {
		color: $color;
		&:hover {
			text-decoration: none;
		}
	}
	+.breadcrumb-item2 {
		&::before {
			display: inline-block;
			padding-right: .5rem;
			padding-left: .5rem;
			content: "/";
			opacity: 0.6;
			color: $muted;
			margin-top:1px;
		}
		&:hover::before {
			text-decoration: none;
		}
	}
	&.active {
		color: $primary ;
	}
}
.page-header {
	.breadcrumb-item+.breadcrumb-item  {
		&:before {
			content: "\e92f";
			opacity: 0.4;
			font-family: "feather";
		}
		padding-left: 0;
	}
}
